.justify-content-center {
  justify-content: center !important;
}
.d-flex {
  display: flex !important;
}

.text-primary {
  color: #180049 !important;
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}
#loadingContainer .loading .spinner-border{
  margin-top: 40vh;
}
#loadingContainer .loading{
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgb(0, 0, 0, 0.25);
  z-index: 9999;
}