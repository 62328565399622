.btn_mode_view{
  background: transparent;
    border: 0;
    padding: 0;
}
.btn_mode_view:focus{
    outline: none;
}
.list_contract .contract_content_main ul li .item-content li .number {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid #cdcdcd;
    margin-right: 3px;
    line-height: 24px;
}
.btn-download-pdf{
    border: none;
    background: transparent;
}
.list_contract_grid .thumbnail-pdf{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.list_contract .contract_content_main.list_contract_grid .list-content .item .item-content{
    overflow: hidden;
    background-size: cover;
}
.list_contract .contract_content_main ul li .item-content li{
    padding: 0;
}
.list_contract .contract_content_main.list_contract_grid .list-content .item .item-content li:nth-child(5){
    display: flex;
}