/* --------------------------------------------------------------------- */
/* COMMON STYLING
/* --------------------------------------------------------------------- */
body {
  font-family: "YuGothic", sans-serif;
  font-weight: normal;
  font-size: 14px;
}
.rw-f12 {
  font-size: 12px;
}
.rw-f14 {
  font-size: 14px;
}
.rw-f16 {
  font-size: 16px;
}
.rw-f18 {
  font-size: 18px;
}
.rw-f20 {
  font-size: 20px;
}
.rw-f22 {
  font-size: 22px;
}
.rw-f24 {
  font-size: 24px;
}
.rw-f26 {
  font-size: 26px;
}
.rw-f28 {
  font-size: 28px;
}
.rw-f30 {
  font-size: 30px;
}
.rw-f32 {
  font-size: 32px;
}
.rw-f34 {
  font-size: 34px;
}
.rw-f36 {
  font-size: 36px;
}
.rw-f38 {
  font-size: 38px;
}
.rw-f40 {
  font-size: 40px;
}
.rw-f42 {
  font-size: 42px;
}
.rw-f44 {
  font-size: 44px;
}
.rw-f46 {
  font-size: 46px;
}
.rw-f48 {
  font-size: 48px;
}
.rw-f50 {
  font-size: 50px;
}
.rw-f52 {
  font-size: 52px;
}
.rw-f54 {
  font-size: 54px;
}
.rw-f56 {
  font-size: 56px;
}
.rw-f58 {
  font-size: 58px;
}
.rw-f60 {
  font-size: 60px;
}
img {
  max-width: 100%;
  vertical-align: middle;
}
a {
  color: #2d89ef;
}
a:hover {
  color: #106fd9;
}
a:focus {
  color: #2d89ef;
}
.rw-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.rw-show {
  display: block !important;
}
.rw-hidden {
  display: none !important;
}
.rw-margin-auto {
  margin: auto;
}
.page-bar .page-breadcrumb > li > a,
.page-bar .page-breadcrumb > li > span,
.page-bar .page-breadcrumb > li > i {
  font-size: 34px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "YuGothic", sans-serif;
  font-weight: normal;
}
.primary-font {
  font-family: "YuGothic", sans-serif !important;
}
.chart,
.pie,
.bars {
  font-family: "YuGothic", sans-serif !important;
}
.label {
  font-family: "YuGothic", sans-serif;
}
.table.table-light > thead > tr > th {
  font-family: "YuGothic", sans-serif;
}
.rw-pb-50 {
  padding-bottom: 50px;
}
.rw-border-top {
  border-top: 1px solid #e1e1e1;
}
.rw-border-bottom {
  border-bottom: 1px solid #e1e1e1;
}
.rw-overflow-ellipsis {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rw-gap {
  border: 0 none;
  padding: 0;
  margin: 0;
}
.rw-bg-white {
  background-color: #fff;
}
.rw-bg-gray {
  background-color: #f5f5f5;
}
.tabbable-custom > .nav-tabs > li > a {
  font-size: 16px;
}
.tabbable-custom > .nav-tabs > li > a,
.tabbable-custom > .nav-tabs > li > a:hover {
  color: #555;
}
.tabbable-custom > .nav-tabs > li.active {
  border-color: #2d89ef;
}
.tabbable-custom > .nav-tabs > li.active > a,
.tabbable-custom > .nav-tabs > li.active > a:hover {
  font-weight: bold;
  color: #2d89ef;
}
.rw-btn-radio-wrapper .btn.active {
  color: #fff;
  border-color: #2d89ef;
  background: #2d89ef;
}
.rw-btn-checkbox-wrapper .btn.active {
  color: #fff;
  background: #2d89ef;
}
.rw-badge-o {
  color: #2d89ef;
}
.rw-badge-x {
  color: #f3565d;
}
.rw-col-wrapper:before,
.rw-col-wrapper:after {
  content: " ";
  clear: both;
  display: table;
}
.rw-col-wrapper .rw-col {
  float: left;
}
.rw-col-wrapper .rw-col-left {
  float: left;
}
.rw-col-wrapper .rw-col-right {
  float: right;
}
table.text-center tr th,
table.text-center tr td {
  text-align: center;
}
.rw-dp-table {
  display: table;
  table-layout: fixed;
  width: 100%;
  vertical-align: top;
  border-collapse: collapse;
}
.rw-dp-table-caption {
  display: table-caption;
}
.rw-dp-table-row {
  display: table-row;
}
.rw-dp-table-cell {
  display: table-cell;
  vertical-align: top;
}
.rw-dp-table-cell.align-top {
  vertical-align: top;
}
.rw-dp-table-cell.align-middle {
  vertical-align: middle;
}
.rw-dp-table-cell.align-bottom {
  vertical-align: bottom;
}
.rw-dp-table-cell.no-border {
  border: 0 none;
}
.rw-dp-table.align-top .rw-dp-table-cell {
  vertical-align: top;
}
.rw-dp-table.align-middle .rw-dp-table-cell {
  vertical-align: middle;
}
.rw-dp-table.align-bottom .rw-dp-table-cell {
  vertical-align: bottom;
}
.rw-dp-table.no-border .rw-dp-table-cell {
  border: 0 none;
}
.rw-dp-ib {
  display: inline-block;
  vertical-align: top;
}
.rw-dp-ib.align-top {
  vertical-align: top;
}
.rw-dp-ib.align-middle {
  vertical-align: middle;
}
.rw-dp-ib.align-bottom {
  vertical-align: bottom;
}
.modal-header:before,
.modal-body:before,
.modal-footer:before,
.modal-header:after,
.modal-body:after,
.modal-footer:after {
  content: " ";
  clear: both;
  display: table;
}
.rw-pagination {
  display: block;
  width: 100%;
  text-align: center;
  bottom: 0;
}
.rw-pagination:before,
.rw-pagination:after {
  content: " ";
  clear: both;
  display: table;
}
.rw-pagination .pagination {
  display: inline-block;
  vertical-align: middle;
}
.rw-pagination .pagination > li > a:focus,
.rw-pagination .pagination > li > span:focus {
  color: #5b9bd1;
  background: #fff;
}
.rw-pagination .pagination .active > a,
.rw-pagination .pagination .active > a:hover {
  color: #fff;
  background: #2d89ef;
  border-color: #2d89ef;
}
.rw-container-style-1 {
  max-width: 888px;
  margin-left: auto;
  margin-right: auto;
}
.rw-container-style-1:before,
.rw-container-style-1:after {
  content: " ";
  clear: both;
  display: table;
}
.rw-block-style-1:before,
.rw-block-style-1:after {
  content: " ";
  clear: both;
  display: table;
}
.rw-block-style-1 .block-1-thumb {
  width: 200px;
}
.rw-block-style-1 .block-1-thumb-img {
  display: block;
  width: 170px;
  height: 210px;
  background-repeat: no-repeat;
  background-size: cover;
}
.rw-block-style-1 .block-1-title {
  margin-top: 0;
}
.rw-block-style-1 .block-1-info table td {
  padding: 3px 15px;
}
.rw-block-style-1 .block-1-info .rw-dp-table {
  width: auto;
}
.rw-block-style-1 .block-1-info .col-table table {
  margin-right: 20px;
}
@media screen and (min-width: 1200px) {
  .rw-modal-large .modal-dialog {
    width: 1000px;
  }
}
@media screen and (max-width: 1199px) {
  .rw-modal-large .modal-dialog {
    width: auto;
  }
}
.rw-thumb-style-1 .thumb-img {
  display: block;
  width: 300px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: auto;
  margin-left: auto;
}
.rw-file-upload .no-image {
  display: none;
}
.rw-file-upload .preview-image {
  text-align: center;
  padding-top: 10px;
  margin-bottom: 40px;
}
.rw-file-upload .preview-image img {
  margin-left: auto;
  margin-right: auto;
}
.rw-file-upload .show-file-info {
  min-height: 34px;
  line-height: 34px;
  height: 100%;
  white-space: nowrap;
  overflow-y: hidden;
  padding-top: 0;
  padding-bottom: 0;
}
.rw-file-upload .btn-delete {
  cursor: pointer;
}
[class*="tr-block-style-"]:before,
[class*="tr-block-style-"]:after {
  content: " ";
  clear: both;
  display: table;
}
.tr-block-style-1 .tr-file-upload {
  float: left;
}
.tr-block-style-1 .btn-select-file {
  /* visibility: hidden; */
  background: #d9d9d9;
  border: 1px solid #cccccc;
  color: #333333;
  display: inline-block;
  padding: 3px 25px;
  position: relative;
  bottom: 20px;
  left: 10px;
  /* opacity: 0; */
  /* position: absolute;
      right: 10px;
      top: 10px;
      left: auto;
      bottom: auto; */
  cursor: pointer;
  min-width: 0;
  transition: all 0.4s ease;
  /* &:active {
        opacity: 0.6 !important;
        transition: none;
      } */
}
.tr-block-style-1 .btn-select-file img {
  display: block !important;
}
.tr-block-style-1 .preview-image {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  background-color: #fff;
  padding: 0;
  margin: 0;
  border-radius: 50% !important;
}
.tr-block-style-1 .preview-image > .image-bg {
  display: block;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: 60px 60px;
  background-position: center top;
  margin: 0;
  border-radius: 50% !important;
}
.tr-block-style-1 .preview-image.no-image {
  background-color: #f1f1f1;
}
.tr-block-style-1 .preview-image.no-image > .image-bg {
  display: none;
}
.tr-block-style-1 .preview-image:hover .btn-select-file {
  visibility: visible;
  opacity: 1;
}
.tr-block-style-1 .note-input-img {
  display: inline-block;
  text-align: left;
  margin-left: 20px;
  position: relative;
  bottom: 8px;
}
@media screen and (max-width: 1919px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (max-width: 1365px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (max-width: 1199px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (max-width: 991px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (max-width: 767px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (max-width: 479px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (max-width: 319px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (min-width: 320px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (min-width: 480px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (min-width: 768px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (min-width: 992px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (min-width: 1200px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (min-width: 1366px) {
  .test-responsive {
    color: #fff;
  }
}
@media screen and (min-width: 1920px) {
  .test-responsive {
    color: #fff;
  }
}
/* --------------------------------------------------------------------- */
/* .login
/* --------------------------------------------------------------------- */
.login-page {
  background: #fff;
  padding-left: 0 !important;
}
.login {
  padding-top: 0px !important;
}
.login .page-container .login_form {
  position: relative;
  background: none;
}
.login .page-container .login_form .login_content h1 {
  font-size: 43px;
  font-weight: bold;
  font-family: "YuGothicBold", sans-serif;
  color: #2d89ef;
  text-align: center;
}
.login .page-container .login_form .login_content h1:before,
.login .page-container .login_form .login_content h1:after {
  display: none;
}
.login .page-container .login_form .login_content .item_form {
  float: left;
  width: 100%;
}
.login .page-container .login_form .login_content .item_form input {
  margin-top: 0;
}
.login .page-container .login_form .login_content .note-vali {
  color: red;
}
.login .page-container .login_form .radio-list input {
  height: 15px;
  margin-top: 2px;
  margin-right: 5px;
  float: left;
}
.login .page-container .login_form .radio-list label {
  float: left;
  width: 100%;
}
.login .page-container .login_form .login_sub {
  text-align: left;
  font-size: 14px;
  margin-bottom: 22px;
}
.login .page-container .login_form .radio-list {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
.login .page-container .login_form .radio-list div {
  margin-right: 5px !important;
}
.login .page-container .login_form .radio-list div span {
  width: 19px;
  height: 19px;
}
.login .page-container .login_form .item_form {
  position: relative;
  margin-bottom: 15px;
}
.login .page-container .login_form .item_form .icon {
  position: absolute;
  width: 34px;
  height: 34px;
  background: #fff;
  top: 0;
  left: 0;
  display: inline-block;
  text-align: center;
  line-height: 34px;
  border: 1px solid #d8d8d8;
}
.login .page-container .login_form .item_form .icon:before {
  background: url(../images/user-avatar.png) no-repeat center;
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  left: 5px;
}
.login .page-container .login_form .item_form .icon.icon_pass:before {
  background: url(../images/password2.png) no-repeat center;
  content: "";
}
.login .page-container .login_form .item_form .icon.icon_mail:before {
  background: url(../images/email.png) no-repeat center;
  content: "";
}
.login .page-container .login_form .item_form .icon.icon_company:before {
  background: url(../images/partners.png) no-repeat center;
  content: "";
}
.login .page-container .login_form .item_form .icon.icon_title:before {
  background: url(../images/title.png) no-repeat center;
  content: "";
}
.login .page-container .login_form .item_form .note_er {
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 12px;
  text-align: left;
  color: #2d89ef;
}
.login .page-container .login_form .if_check input {
  float: left;
  margin-top: -7px;
  margin-right: 5px;
}
.login .page-container .login_form .submit-form {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.login .page-container .login_form .button-submit {
  width: 120px;
  height: 44px;
  background: #2d89ef;
  text-align: center;
  line-height: 42px;
  padding: 0;
  padding-bottom: 4px;
  border-radius: 0;
  font-size: 18px;
  margin: 0 3px;
  color: #fff;
}
.login .page-container .login_form .button-submit.btn-back {
  background: #9e9f9f;
}
.login .logo {
  text-align: center;
  margin-bottom: 80px;
}
.login .form-login-wrapper {
  display: block;
  width: 100%;
}
.login .form-login {
  width: 400px;
  background: #fff;
  padding: 30px;
  margin: 0 auto;
}
.login .form-login .form-header {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  color: #2d89ef;
  margin-bottom: 20px;
}
.login .form-login .inputbox {
  display: block;
  width: 100%;
  height: 46px;
  color: #5a5a5a;
  background: #ebf3ff;
  border: 0 none;
  box-shadow: none;
  outline: 0;
  padding: 0 10px;
}
.login .form-login .remember-checkbox {
  font-weight: normal;
  color: #474747;
  cursor: pointer;
  margin-bottom: 0;
}
.login .form-login .remember-checkbox input[type="checkbox"] {
  position: relative;
  top: 2px;
  margin-top: 0;
  margin-right: 10px;
}
.login .form-login .btn-login {
  display: block;
  width: 100%;
  height: 46px;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  background: #2d89ef;
  border: 0 none;
  box-shadow: none;
  outline: 0;
  padding: 12px 10px;
}
.login .form-login .btn-login:hover {
  background: #106fd9;
}
.login .error-message {
  color: #f74949;
}
/* --------------------------------------------------------------------- */
/* .header
/* --------------------------------------------------------------------- */
body {
  position: relative;
  background: #fff;
  padding-left: 0px !important;
  color: #0f0f0e;
  padding-top: 65px !important;
}
.login .login_wrapper {
  width: 469px;
  padding: 20px 35px;
  position: absolute;
  top: 100px;
  left: 50%;
  margin-left: -235px;
}
.login .login_wrapper input {
  border: 1px solid #d8d8d8;
  background: #e8f0fe;
  box-shadow: none;
  padding-left: 50px;
  line-height: 32px;
  height: 34px;
}
.login .login_wrapper input:focus {
  border: 1px solid #d8d8d8;
  background: #e8f0fe;
}
.login .login_wrapper.register {
  width: 800px;
  padding: 20px 35px;
  position: absolute;
  top: 15px;
  left: 50%;
  margin-left: -400px;
}
.login .login_wrapper.register .form-group {
  margin-bottom: 0.6rem;
}
.login .login_wrapper.register .radio-list {
  padding-left: 34%;
}
.login .login_wrapper.register .login_form .login_content .submit-form .button-submit {
  width: 180px;
}
.header {
  /**/
}
.page-header {
  margin: 0;
  background: #2d89ef;
  padding: 0;
  height: 59px;
}
.page-header .logo {
  float: left;
  padding-top: 15px;
  margin-left: 15px;
}
.page-header .top-megamenu {
  display: inline-block;
  float: left;
  line-height: 52px;
  text-align: left;
  padding-right: 60px;
  width: auto;
}
.page-header .top-megamenu span {
  color: #fff;
  font-size: 16px;
}
.page-header .top-menu-acc {
  float: right;
}
.page-header .top-menu-acc span {
  line-height: 58px;
  color: #fff;
}
.page-header .top-menu .dropdown-user .dropdown-toggle img {
  max-width: 40px;
  float: left;
  margin-top: 8px;
}
.page-header .top-menu .dropdown-user span {
  color: #fff;
  display: inline-block;
  line-height: 50px;
  margin-left: 5px;
}
.page-header .top-menu .dropdown-user.open a {
  background: none;
}
.page-header .top-menu .dropdown-user .dropdown-menu {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.175);
  left: inherit;
  right: 0;
  width: 180px;
}
.page-header .top-menu .dropdown-user .dropdown-menu:before {
  left: inherit;
  right: 10px;
}
.page-header .top-menu .dropdown-user .dropdown-menu:after {
  left: inherit;
  right: 10px;
}
.page-header .top-menu .dropdown-user .dropdown-menu img {
  margin-right: 10px;
  float: left;
}
.page-header .top-menu .dropdown-user .dropdown-menu a {
  padding: 8px 0;
}
.page-header .top-menu .dropdown-user .dropdown-menu a:hover {
  text-decoration: underline;
}
.sidebar-menu {
  float: left;
  width: 100%;
}
.sidebar-left {
  position: fixed;
  width: 300px;
  z-index: 9;
  height: 100% !important;
  top: 0;
  left: 0;
  background: #3a3a3a;
}
.sidebar-left .page-sidebar {
  background: #3a3a3a;
}
.sidebar-left .left_col {
  background: #3a3a3a;
}
.sidebar-left .left_col .nav_title {
  width: 100%;
  height: 64px;
  background: #080808;
}
.sidebar-left .left_col .nav_title .site_title {
  height: 64px;
  line-height: 58px;
  padding-left: 40px;
  font-size: 26px;
}
.sidebar-left .left_col #sidebar-menu .menu_section .side-menu {
  margin-top: 0;
}
.sidebar-left .left_col #sidebar-menu .menu_section .side-menu li {
  border-bottom: 1px solid #bebebe;
}
.sidebar-left .left_col #sidebar-menu .menu_section .side-menu li a {
  font-size: 24px;
  height: 68px;
  line-height: 50px;
  color: #fbfbfb;
  transition: all 0.3s;
}
.sidebar-left .left_col #sidebar-menu .menu_section .side-menu li a:hover {
  color: #fff;
  padding-left: 20px;
  transition: all 0.3s;
}
.sidebar-left .left_col #sidebar-menu .menu_section .side-menu li a img {
  float: left;
  margin-top: 12px;
  margin-right: 8px;
}
.sidebar-left .page-logo {
  float: left;
  width: 100%;
}
.sidebar-left .page-logo a {
  float: left;
  width: 100%;
  padding-left: 20px;
  padding-bottom: 15px;
}
.sidebar-left .profile {
  float: left;
  width: 100%;
  margin-top: 10px;
  color: #fff;
  margin-bottom: 20px;
}
.sidebar-left .profile .profile_pic {
  width: 60px;
  height: 60px;
  float: left;
  margin-left: 20px;
  border: 3px solid #fff;
  display: inline-block;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}
.sidebar-left .profile .profile_pic img {
  max-width: 100%;
  width: 60px;
  height: 60px;
}
.sidebar-left .profile .profile_info {
  display: inline-block;
  padding-left: 15px;
  padding-top: 5px;
}
.sidebar-left .profile .profile_info h3 {
  margin: 0;
  margin-top: 10px;
  font-size: 16px;
}
.sidebar-left .sidebar-menu {
  background: none;
}
.sidebar-left .sidebar-menu > li > a {
  color: #e7e7e7;
  padding: 13px 15px 12px;
  margin-bottom: 6px;
}
.sidebar-left .sidebar-menu > li > a:hover {
  background: linear-gradient(#334556, #2c4257), #2a3f54;
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
}
.sidebar-left .sidebar-menu > li > a span {
  margin-left: 10px;
}
.sidebar-left .sidebar-menu > li > a > i.fa {
  font-size: 16px;
}
.sidebar-left .sidebar-menu > li.active > a {
  background: linear-gradient(#334556, #2c4257), #2a3f54;
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
}
.sidebar-left .sidebar-menu .sidebar-submenu {
  background: none;
  padding-left: 0;
}
.sidebar-left .sidebar-menu .sidebar-submenu li a:hover {
  background: rgba(255, 255, 255, 0.06);
}
/* --------------------------------------------------------------------- */
/* .header
/* --------------------------------------------------------------------- */
.page-name {
  /**/
}
.disabled {
  position: relative;
}
.disabled:before {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  content: "";
}
.td-find-id {
  position: relative;
  max-width: 100px !important;
  overflow: hidden;
}
.page-header-fixed .page-container {
  margin-top: 0px;
}
.title-page h3 {
  float: left;
  margin-top: 0;
  margin-right: 10px;
}
.title-page span {
  line-height: 30px;
}
.portlet-title .caption {
  color: #333333;
}
.mrb-100 {
  margin-bottom: 100px;
}
.mrb-90 {
  margin-bottom: 90px;
}
.mrb-80 {
  margin-bottom: 80px !important;
}
.mrb-70 {
  margin-bottom: 70px;
}
.mrb-60 {
  margin-bottom: 60px;
}
.mrb-50 {
  margin-bottom: 50px;
}
.mrb-40 {
  margin-bottom: 40px;
}
.mrb-30 {
  margin-bottom: 30px !important;
}
.mrb-20 {
  margin-bottom: 20px;
}
.mrb-10 {
  margin-bottom: 10px;
}
.mrt-100 {
  margin-top: 100px;
}
.mrt-90 {
  margin-top: 90px;
}
.mrt-80 {
  margin-top: 80px;
}
.mrt-70 {
  margin-top: 70px;
}
.mrt-60 {
  margin-top: 60px;
}
.mrt-50 {
  margin-top: 50px;
}
.mrt-40 {
  margin-top: 40px;
}
.mrt-30 {
  margin-top: 30px !important;
}
.mrt-20 {
  margin-top: 20px;
}
.mrt-10 {
  margin-top: 10px;
}
.mrl-50 {
  margin-left: 50px;
}
.mrl-40 {
  margin-left: 40px;
}
.mrl-30 {
  margin-left: 30px !important;
}
.mrl-20 {
  margin-left: 20px;
}
.mrl-10 {
  margin-left: 10px;
}
.mrr-100 {
  margin-right: 100px;
}
.mrr-90 {
  margin-right: 90px;
}
.mrr-80 {
  margin-right: 80px;
}
.mrr-70 {
  margin-right: 70px;
}
.mrr-60 {
  margin-right: 60px;
}
.mrr-50 {
  margin-right: 50px;
}
.mrr-40 {
  margin-right: 40px;
}
.mrr-30 {
  margin-right: 30px;
}
.mrr-20 {
  margin-right: 20px;
}
.mrr-5 {
  margin-right: 5px;
}
.mrr-10 {
  margin-right: 10px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-60 {
  margin-bottom: 60px;
}
.pdt-5 {
  padding-top: 5px;
}
.pdt-10 {
  padding-top: 10px;
}
.pdt-10 {
  padding-top: 10px;
}
.pdt-20 {
  padding-top: 20px;
}
.pdt-30 {
  padding-top: 30px;
}
.pdt-40 {
  padding-top: 40px;
}
.pdt-50 {
  padding-top: 50px;
}
.pdl-10 {
  padding-left: 10px;
}
.pdl-20 {
  padding-left: 20px;
}
.pdl-30 {
  padding-left: 30px;
}
.pdl-40 {
  padding-left: 40px;
}
.pdl-50 {
  padding-left: 50px;
}
.pdb-10 {
  padding-bottom: 10px;
}
.pdb-20 {
  padding-bottom: 20px;
}
.pdb-30 {
  padding-bottom: 30px;
}
.pdb-40 {
  padding-bottom: 40px;
}
.pdb-50 {
  padding-bottom: 50px;
}
.pad-10-30 {
  padding: 10px 30px;
}
.pad-10-50 {
  padding: 10px 50px;
}
.pad-0-30 {
  padding: 0px 30px;
}
.pad-0-10 {
  padding: 0px 10px;
}
.pad-20-30 {
  padding: 20px 30px;
}
.pad-5-10 {
  padding: 5px 10px;
}
.pad-5-30 {
  padding: 5px 30px;
}
.pad-5-50 {
  padding: 5px 50px;
}
.pad-8-50 {
  padding: 8px 50px;
}
.mr-left15 {
  margin-left: 15px;
}
.mr-right30 {
  margin-right: 30px;
}
.red {
  color: #dc3c40;
}
.text-gray {
  color: #7e7e7e;
}
.line32 {
  line-height: 32px;
}
.line-34 {
  line-height: 34px;
}
.line-35 {
  line-height: 35px;
}
.line-18 {
  line-height: 18px;
}
.bg-f3 {
  background: #f3f3f3;
}
.btn-primary {
  background: #2d89ef;
}
.btn-default {
  border: 1px solid #b1b1b1;
  background: #efefef;
  padding: 0 25px;
  padding-bottom: 3px;
  line-height: 24px;
}
.btn-green {
  background: #149c14;
  color: #fff;
}
.btn-green:hover {
  color: #fff;
  background: #118a11 !important;
}
.border-radius-4 {
  border-radius: 4px;
}
.pic-maps {
  float: left;
  margin-left: 20px;
  margin-top: -7px;
}
.modal-dialog.width-980 {
  width: 980px;
}
.select2-container-multi .select2-choices {
  min-height: 42px;
  background: none;
}
.form-control.select2-container {
  width: 100%;
  background: none;
}
.select_2 .select2-container {
  position: relative;
  z-index: 99;
}
span.vali {
  margin-left: 15px;
}
.btn-ping {
  background: #e94475 !important;
  color: #fff;
}
.btn-ping:hover {
  color: #fff;
  background: #db3e6d !important;
}
.ct-col-bt {
  position: relative;
}
.no-border {
  border: none !important;
}
div.checker span {
  width: 22px;
  height: 20px;
  border: 1px solid #707070;
  background: none;
  float: left;
  margin-top: -3px;
}
div.checker span.checked:before {
  position: absolute;
  content: "\f00c";
  font: normal normal normal 14px/1 FontAwesome;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  color: #444;
}
.radio {
  float: left;
  margin-top: 3px !important;
  margin-right: 3px !important;
}
.color444 {
  color: #444;
}
.btn-address {
  position: absolute;
  width: 65px;
  height: 32px;
  background: #939393;
  border-right: 3px;
  text-align: center;
  padding: 0;
  line-height: 24px;
  margin: 0;
  color: #fff;
  padding-bottom: 3px;
  top: 5px;
  right: 5px;
  transition: all 0.3s;
}
.btn-address:hover {
  background: #2d89ef;
  color: #fff;
}
.btn-table {
  width: 128px;
  height: 34px;
  background: #02b2d6;
  border-right: 3px;
  text-align: center;
  padding: 0;
  line-height: 24px;
  margin: 15px 0 !important;
  color: #fff;
  padding-bottom: 5px;
  top: 5px;
  right: 5px;
  transition: all 0.3s;
  opacity: 0.9;
}
.btn-table:hover {
  background: #02b2d6;
  color: #fff;
  opacity: 1;
}
a.btn-table {
  line-height: 28px;
}
.btn-table-2line {
  width: 75px;
  height: 48px;
  background: #e965fa;
  border-right: 3px;
  text-align: center;
  padding: 0;
  line-height: 16px;
  margin: 15px 0 !important;
  color: #fff;
  padding-bottom: 5px;
  top: 5px;
  right: 5px;
  transition: all 0.3s;
  opacity: 0.9;
}
.btn-table-2line:hover {
  background: #e94475;
  color: #fff;
  opacity: 1;
}
.top-page-submit .btn-submit {
  width: 180px;
  height: 44px;
  font-size: 16px;
  padding: 0;
  padding-bottom: 3px;
  margin: 0;
}
.jo-date {
  display: inline-block;
}
.jo-date-show {
  float: left;
  margin-top: -6px;
  margin-left: 50px;
}
.jo-date-show p {
  margin-bottom: 0;
}
.note-table {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  border: 1px solid #666;
  display: inline-block;
}
.navigation {
  text-align: center;
  margin-top: 30px;
}
.navigation ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.rw-pagination .pagination {
  margin-top: 30px;
}
.rw-pagination .pagination li a {
  border: none;
  font-weight: bold;
  font-size: 18px;
  color: #2d89ef;
  padding-left: 7px;
  padding-right: 7px;
  background: none !important;
}
.rw-pagination .pagination li a:hover {
  color: #2d89ef;
}
.rw-pagination .pagination li.active a {
  background: none;
  color: #aaa;
  font-weight: bold;
}
.rw-pagination .pagination li.active a:hover {
  background: none;
  color: #2d89ef;
}
.rw-pagination .pagination li.icon a i {
  color: #444;
  font-size: 20px;
}
.rw-pagination .pagination li.icon a:hover i {
  color: #2d89ef;
}
.floaf-left {
  float: left;
}
.float-right {
  float: right;
}
button {
  outline: none;
}
button:focus {
  outline: none;
}
.full {
  display: inline-block;
  width: 100%;
}
.line-30 {
  line-height: 30px;
  display: inline-block;
}
.line-20 {
  line-height: 20px;
  display: inline-block;
}
.line-42 {
  line-height: 42px;
  display: inline-block;
}
.line-36 {
  line-height: 36px;
  display: inline-block;
}
.line-40 {
  line-height: 30px;
  display: inline-block;
}
.line-60 {
  line-height: 60px;
  display: inline-block;
}
.line-110 {
  line-height: 110px;
  display: inline-block;
}
.bg-green {
  background: #26c6da;
}
.bg-white {
  background: #fff;
}
.select-disable {
  position: relative;
}
.select-disable:before {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 50px;
  top: -1px;
  left: 0;
  opacity: 0.5;
  cursor: no-drop;
  content: "";
  background: #fff;
}
.block2 .item {
  float: left;
  width: 150px;
  margin-right: 20px;
}
.block2 .item .bootstrap-select {
  min-width: 150px;
}
.block2 .item.year {
  width: 80px;
}
.block2 .item.year .bootstrap-select {
  min-width: 80px;
}
.block3 .item {
  float: left;
  width: 100px;
  margin-right: 20px;
}
.block3 .item .bootstrap-select {
  min-width: 100px;
}
.form-date .block1 {
  display: none;
}
.form-date .block1.active-block {
  display: inline-block;
}
.form-date .block2 {
  display: none;
}
.form-date .block2.active-block {
  display: inline-block;
}
.form-date .block3 {
  display: none;
}
.form-date .block3.active-block {
  display: inline-block;
}
.datepicker-form {
  display: inline-block;
  float: left;
  margin-left: 30px;
  margin-top: -5px;
}
.datepicker-form .item.datepicker-item {
  float: left;
  position: relative;
}
.datepicker-form .item.datepicker-item span {
  display: inline-block;
}
.datepicker-form .item.datepicker-item input.date-picker {
  width: 240px !important;
}
.datepicker-form .item.datepicker-item.icon-next:before {
  position: absolute;
  width: 40px;
  height: 40px;
  content: "\f0ec";
  top: 0;
  font-family: FontAwesome;
  right: -50px;
  line-height: 34px;
}
.content-page-note p {
  font-size: 18px;
}
.bootstrap-select {
  height: 42px;
}
.bootstrap-select span {
  line-height: 30px !important;
}
.bootstrap-select .dropdown-toggle {
  padding: 0 10px;
  height: 42px;
}
.bootstrap-select .caret {
  top: 10px;
  right: 7px;
}
.bootstrap-select .dropdown-menu {
  box-shadow: none;
}
.bootstrap-select .dropdown-menu:after,
.bootstrap-select .dropdown-menu:before {
  display: none !important;
}
.bootstrap-select .dropdown-menu ul li a {
  line-height: 30px;
  padding: 0;
  text-align: center;
}
.bootstrap-select .dropdown-menu ul li a .check-mark {
  display: none !important;
}
.select2-drop .select2-search {
  display: none !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.color-333 {
  color: #333;
}
input {
  border-radius: 0 !important;
}
.item-50 {
  float: left;
  width: 50%;
}
.border-dashed {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #4a7ebb;
  margin-bottom: 23px;
}
.controls-button {
  margin: 5px 0 8px;
}
.tabbable-custom ul li.disabled {
  position: relative;
}
.tabbable-custom ul li.disabled:before {
  position: absolute;
  width: 100%;
  content: "";
  z-index: 9;
  height: 100%;
  opacity: 0.6;
  background: #fff;
  cursor: no-drop;
}
.border-bottom {
  border-bottom: 1px solid #cdcdcd;
}
.border-left {
  border-left: 1px solid #cdcdcd;
}
.border-right {
  border-right: 1px solid #cdcdcd;
}
.border-top {
  border-top: 1px solid #cdcdcd;
}
.nav-menu {
  margin: 0 0 20px;
  padding: 0;
  border-bottom: 1px solid #dddddd;
}
.nav-menu li {
  display: inline-block;
}
.nav-menu li.active a {
  background-color: #eee;
}
.nav-menu li > a {
  font-weight: bold;
  font-size: 18px;
}
.nav-right {
  width: 100%;
  margin-bottom: 20px;
  text-align: right;
}
.nav-right li {
  display: inline-block;
}
#calendar {
  max-width: 900px;
  margin: 0 auto;
}
.border_control {
  border: 1px solid #dddddd;
  display: table;
  width: 100%;
  margin-bottom: 15px;
}
.border_control .group_item {
  display: table-cell;
  vertical-align: middle;
  padding: 8px 5px;
}
.border_control .group_item .sub_label {
  display: table-cell;
  width: 35px;
  vertical-align: middle;
}
.border_control .group_item .sub_input {
  display: table-cell;
}
.border_control .group_item .sub_input .item_time {
  display: inline-block;
  vertical-align: middle;
}
.border_control .group_item .sub_input .item_time .input-group-addon {
  border: 1px solid #dddddd;
  position: relative;
  padding: 9px 12px;
}
.border_control .add_item {
  display: block;
}
.border_control .trash {
  border-left: 1px solid #dddddd;
  padding: 8px 10px;
  width: 16px;
}
.border_control .fa-remove {
  font-size: 18px;
}
.item_inline {
  display: inline-block;
  vertical-align: middle;
}
.btn-sumbit {
  padding: 7px 25px;
}
.reviews_result {
  display: inline-block;
  margin-left: 10px;
}
.reviews_result ul.reviews_list {
  margin: 22px 0;
  padding: 0;
  display: inline-block;
}
.reviews_result ul.reviews_list li {
  display: inline-block;
  margin: 0;
}
.reviews_result ul.reviews_list li .star_item {
  width: 19px;
  height: 19px;
}
.reviews_result ul.reviews_list li .star_item img {
  width: 19px;
}
.reviews_result .number_review {
  font-size: 20px;
  color: #888888;
  padding-left: 5px;
  position: relative;
  top: 5px;
  display: inline-block;
}
.list-comment {
  width: 100%;
  padding-top: 20px;
}
.list-comment .item-comment {
  width: 100%;
  border-top: 1px solid #4a7ebb;
  padding: 15px 0;
  display: inline-block;
}
.list-comment .item-comment .member-info {
  margin-bottom: 5px;
}
.list-comment .item-comment .member-info .space {
  padding: 0 7px;
}
.list-comment .item-comment .reviews-result {
  margin: 0 0 5px;
  padding: 0;
}
.list-comment .item-comment .reviews-result li {
  display: inline-block;
  margin: 0;
}
.list-comment .item-comment .reviews-result li .item_reviews {
  width: 14px;
  height: 15px;
}
.list-comment .item-comment .reviews-result li .item_reviews img {
  width: 14px;
}
.list-comment .item-comment .item-question {
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
}
.list-comment .item-comment .item-answer {
  width: 100%;
  padding: 20px 0;
  display: inline-block;
  border-top: 1px dashed #4a7ebb;
}
.list-comment .item-comment .item-answer .avatar {
  width: 5%;
  display: table-cell;
  padding-left: 40px;
}
.list-comment .item-comment .item-answer .avatar img {
  max-width: 35px;
}
.list-comment .item-comment .item-answer .content-anwser {
  width: 70%;
  display: table-cell;
  padding: 0;
  vertical-align: top;
}
.list-comment .last-item {
  border-bottom: 1px solid #4a7ebb;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  vertical-align: middle;
}
.border-full {
  border: 1px solid #cdcdcd;
}
.width100 {
  display: inline-block;
  width: 100px;
}
.font-12 {
  font-size: 12px;
}
.font-16 {
  font-size: 16px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-26 {
  font-size: 26px !important;
}
.font-28 {
  font-size: 28px !important;
}
.bold {
  font-weight: bold;
}
.bootstrap-select {
  height: 34px;
  min-width: 200px;
}
.bootstrap-select button.dropdown-toggle {
  background: none;
  color: #444;
  height: 42px;
  border: 1px solid #e5e5e5 !important;
}
.bootstrap-select button.dropdown-toggle:active,
.bootstrap-select button.dropdown-toggle:focus,
.bootstrap-select button.dropdown-toggle:hover {
  background: #fff !important;
  outline: none !important;
  color: #444 !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
}
.bootstrap-select button.dropdown-toggle span {
  background: none;
  color: #444 !important;
}
.bootstrap-select button.dropdown-toggle .caret {
  right: 10px !important;
  top: 11px !important;
}
.bootstrap-select button.dropdown-toggle.active {
  background: #fff !important;
  color: #444 !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
}
.bootstrap-select button.dropdown-toggle.active:active,
.bootstrap-select button.dropdown-toggle.active:focus,
.bootstrap-select button.dropdown-toggle.active:hover {
  background: #fff !important;
  outline: none !important;
  color: #444 !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
}
.bootstrap-select .dropdown-menu li a {
  line-height: 30px;
}
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  background: none !important;
}
.bootstrap-select.open .btn {
  border: 1px solid #ccc !important;
}
.btn-group.open .dropdown-toggle {
  box-shadow: none !important;
}
.search-icon {
  position: relative;
}
.search-icon input {
  padding-right: 40px;
}
.search-icon button.search {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 2px;
  right: 20px;
  background: transparent;
  border: none;
}
.search-icon button.search i {
  font-size: 18px;
  color: #999;
}
.search-icon button.search:hover i {
  color: #444;
}
.container.width800 {
  width: 800px;
}
.button-onoff {
  /* Rounded sliders */
}
.button-onoff .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.button-onoff .switch input {
  display: none;
}
.button-onoff .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.button-onoff .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.button-onoff input:checked + .slider {
  background-color: #2196f3;
}
.button-onoff input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
.button-onoff input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.button-onoff .slider.round {
  border-radius: 34px;
}
.button-onoff .slider.round:before {
  border-radius: 50%;
}
.border-input {
  border: 1px solid #e5e5e5;
  display: inline-block;
  padding: 5px 30px;
}
.input-search {
  position: relative;
}
.input-search input {
  padding-right: 50px;
}
.input-search i {
  position: absolute;
  width: 34px;
  height: 34px;
  top: 0;
  right: 0;
  display: inline-block;
  line-height: 32px;
  padding-top: 10px;
  text-align: center;
}
.input-search i:hover {
  cursor: pointer;
  color: #02b2d6;
}
.input-search .icon_search {
  position: absolute;
  top: 3px;
  right: 11px;
  display: inline-block;
  line-height: 32px;
  padding-top: 10px;
  text-align: center;
}
.input-search .icon_search:hover {
  cursor: pointer;
  color: #02b2d6;
}
.form-control {
  height: 42px;
}
.width-5 {
  width: 5%;
  float: left;
}
.width-10 {
  width: 10%;
  float: left;
}
.width-15 {
  width: 15%;
  float: left;
}
.width-20 {
  width: 20%;
  float: left;
}
.width-25 {
  width: 25%;
  float: left;
}
.width-30 {
  width: 30%;
  float: left;
}
.width-35 {
  width: 35%;
  float: left;
}
.width-40 {
  width: 40%;
  float: left;
}
.width-45 {
  width: 45%;
  float: left;
}
.width-50 {
  width: 50%;
  float: left;
}
.width-55 {
  width: 55%;
  float: left;
}
.width-60 {
  width: 60%;
  float: left;
}
.width-65 {
  width: 65%;
  float: left;
}
.width-70 {
  width: 70%;
  float: left;
}
.width-75 {
  width: 75%;
  float: left;
}
.width-80 {
  width: 80%;
  float: left;
}
.width-90 {
  width: 90%;
  float: left;
}
.width-100 {
  width: 100%;
  float: left;
}
.width50 {
  width: 50%;
}
.width75 {
  width: 75%;
}
.width-7 {
  width: 7%;
  float: left;
}
.mrl-5 {
  margin-left: 5px;
}
.mrr-5 {
  margin-right: 5px;
}
.w-16 {
  width: 16px !important;
}
.h-16 {
  height: 16px !important;
}
.mrr-35 {
  margin-right: 35px;
}
.mrl-35 {
  margin-left: 35px;
}
.flr {
  float: right;
}
input.rating[type="number"] {
  border: 0;
  outline: none;
  width: 80px;
  position: relative;
  top: 3px;
}
.tab-counselor-detail .form-group .right {
  position: relative;
  right: -15px;
}
.tab-counselor-detail .form-group .item-review .glyphicon {
  font-size: 16px;
  position: relative;
  top: 3px;
}
.tab-counselor-detail .form-group .item-review .glyphicon-star {
  color: #45bdef;
}
.tab-counselor-detail .form-group .item-review .glyphicon-star-empty {
  top: 5px;
}
.margin-top {
  border-top: 1px solid #cccccc;
  margin-top: 20px;
  padding-top: 10px;
  width: 100%;
}
.table-schedule th,
.table-schedule td {
  vertical-align: middle !important;
}
.list-sub-checkbox {
  display: none;
  padding-left: 15px;
}
.list-sub-checkbox label {
  display: block;
}
.item-counselor_detail > .bootstrap-select {
  width: 200px !important;
}
.btn-download {
  font-size: 13px;
  color: #333333;
}
.btn-download.btn-doc {
  padding-left: 35px;
  height: 36px;
  background: url(../images/icon/icon_typeDoc.png) left 3px no-repeat !important;
  background-size: 27px;
  padding-top: 13px;
}
.btn-download.btn-pdf {
  height: 36px;
  padding-left: 35px;
  background: url(../images/icon/pdf-icon1.png) left 3px no-repeat !important;
  background-size: 25px;
}
.content-delivery .form-control {
  height: 32px;
}
.content-delivery .bootstrap-select button.dropdown-toggle {
  height: 32px;
}
.content-delivery .check-demo div.checker span {
  height: 17px;
  width: 17px;
  border-radius: 5px;
}
.content-delivery .check-demo div.checker span.checked:before {
  height: 17px;
  width: 17px;
  line-height: 17px;
}
.posre-text {
  position: relative;
}
.posab-text {
  position: absolute;
  top: -40px;
}
.bold {
  font-weight: 700;
}
.btn-confix {
  height: 22px;
  font-size: 12px;
  line-height: 20px;
  width: 42px;
  color: #fff;
}
.black {
  color: #000;
}
.bor-b {
  border-bottom: 1px solid #707070;
}
.bor-td {
  border-bottom: 1px solid #cdcdcd;
}
.mrt-40 {
  margin-top: 40px;
}
.bgr-tran {
  background: transparent;
  padding-left: 0px;
}
.btn-yellow {
  background: #eb3c00;
  color: #fff;
  font-size: 16px;
  line-height: 21px;
}
.btn-custom {
  width: 110px !important;
  height: 32px !important;
}
.mrl-35 {
  margin-left: 35px !important;
}
.bgr-blue {
  background: #02b2d6;
  color: #fff;
}
.btn-green {
  background: #149c14;
}
.fnt-30 {
  font-size: 30px;
  font-weight: 700;
  color: #000;
  font-family: "YuGothic";
}
table tr td {
  padding: 5px;
}
table tr td .btn {
  height: 32px;
  line-height: 16px;
  width: 100%;
  margin-bottom: 0px;
  margin-right: 0px;
}
.scroll-content {
  overflow-y: scroll;
  height: 130px;
}
.scroll-content ::-webkit-scrollbar {
  width: 10px;
}
/* Track */
.scroll-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
.scroll-content::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
.scroll-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.page-header {
  background: #2d89ef;
  border-bottom: none;
  height: 59px;
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
}
.page-header .icon-menu {
  display: none;
}
.page-header .top-menu .navbar-nav {
  margin-top: 2px;
}
.page-header .top-menu .navbar-nav .dropdown-user .dropdown-menu {
  position: absolute;
}
.page-header .top-menu .navbar-nav .dropdown-user .dropdown-toggle img {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  margin-top: 12px;
}
.page-header .top-menu .navbar-nav .dropdown-user .dropdown-toggle .username {
  background: none;
  box-shadow: none;
  border: none;
  color: #fff;
  line-height: 48px;
}
.login_company_step2 {
  position: relative;
}
.login_company_step2 .form-group {
  float: left;
  width: 30px;
}
.cusomer-file .input-icon {
  float: left;
  border: 1px solid #e5e5e5;
  width: 80%;
  height: 34px;
}
.cusomer-file .input-icon .btn-delete {
  float: right;
  margin-right: 10px;
  margin-top: 5px;
}
.cusomer-file .input-icon .btn-delete:hover {
  cursor: pointer;
}
.cusomer-file .input-icon .show-file-info {
  line-height: 30px;
  margin-left: 10px;
}
.page-header.navbar {
  background: #202a2f;
}
.submit-alldate {
  position: relative;
  float: right;
}
.submit-alldate:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  cursor: no-drop;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
}
.submit-alldate.no-submit:before {
  display: block;
}
.page-logo {
  padding-left: 0 !important;
}
.page-logo img {
  float: left;
  margin-top: 12px !important;
}
.page-logo span {
  float: left;
  color: #fff;
  margin-left: 10px;
  margin-top: 15px;
  font-size: 16px;
}
.page-container {
  padding: 0;
  margin: 0;
}
.datepicker-item:after {
  position: absolute;
  width: 200px;
  height: 42px;
  border: 1px solid #e5e5e5;
  top: 1px;
  left: 0;
  content: "";
}
.datepicker-item span {
  position: relative;
  z-index: 3;
}
.datepicker-item span input {
  background: none;
  border: transparent;
}
.datepicker-item span input:focus {
  border-color: #e5e5e5;
}
.datepicker-item span.text {
  position: absolute;
  z-index: 8;
  width: 50px;
  height: 34px;
  line-height: 34px;
  top: -1px;
  right: -10px;
  color: #222;
  text-align: center;
}
.top-page-submit .btn-maps-update {
  width: 60px;
  height: 33px;
  color: #fff;
  font-size: 22px;
  padding: 0;
  background: #979494;
  padding-bottom: 1px;
  float: left;
  margin-left: 30px;
  margin-right: 30px;
}
.sw-input {
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}
.sw-input .switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}
.sw-input .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.sw-input .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.sw-input .slider:before {
  position: absolute;
  content: "OFF";
  height: 26px;
  width: 40px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  padding: 0;
  line-height: 26px;
  text-align: center;
  color: #2196f3;
}
.sw-input input:checked + .slider {
  background-color: #2196f3;
}
.sw-input input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
.sw-input input:checked + .slider:before {
  -webkit-transform: translateX(32px);
  -ms-transform: translateX(32px);
  transform: translateX(32px);
  content: "ON";
}
.sw-input .slider.round {
  border-radius: 10px;
}
.sw-input .slider.round:before {
  border-radius: 10px;
}
.page-sidebar-wrapper.vetical-menu {
  display: block;
  position: relative;
  float: left;
  height: 58px;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar {
  width: 100% !important;
  position: relative;
  display: block;
  background: none;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul {
  display: inline-block;
  width: 100%;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul li {
  display: inline-block;
  height: 59px;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul li a {
  margin: 0;
  padding: 0 30px;
  line-height: 54px;
  border: none;
  color: #333;
  background: none;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul li a:hover {
  border: none;
  color: #02b2d6;
  background: none;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul li a:hover i {
  color: #02b2d6;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul li .dropdown-menu {
  top: 100%;
  height: 120px;
  box-shadow: none;
  display: none;
  margin: 0;
  border: 1px solid #d9dee4;
  border-radius: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #ededed;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul li .dropdown-menu li {
  float: left;
  width: 100%;
  height: 32px;
  margin: 0;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul li .dropdown-menu li a {
  float: left;
  display: inline-block;
  line-height: 32px;
  width: 100%;
  font-size: 13px;
  padding: 0;
  padding-left: 15px;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul li .dropdown-menu li a:hover {
  color: #02b2d6;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul li.dropup.open .dropdown-menu {
  top: 100%;
  display: block;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul li.active {
  background: #fff;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul li.active a {
  color: #2d89ef;
}
.page-sidebar-wrapper.vetical-menu .page-sidebar ul li.active a span {
  color: #2d89ef;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:last-child > a,
.page-sidebar .page-sidebar-menu > li:last-child > a {
  border: none !important;
}
.page-content-wrapper .page-content {
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
}
.page-content-wrapper .home-top {
  width: 100px;
  display: inline-block;
  width: 100%;
  font-size: 18px;
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center;
}
.page-content-wrapper .home-top span.bold {
  margin-left: 20px;
}
.page-content-wrapper .home-top2 {
  padding: 20px;
  margin-bottom: 5px;
}
.page-content-wrapper .home-top2 .top {
  margin-bottom: 20px;
}
.page-content-wrapper .home-top2 .top a {
  text-decoration: underline;
}
.page-content-wrapper .home-top2 .content .item {
  float: left;
  width: 50%;
  padding: 0 15px;
  margin-bottom: 15px;
}
.page-content-wrapper .home-top2 .content .item .border-full {
  display: inline-block;
  width: 100%;
  height: 50px;
  line-height: 48px;
  padding: 0 15px;
}
.page-content-wrapper .home-top2 .content .item .border-full span {
  margin-right: 10px;
}
.page-content-wrapper .home-top3 {
  margin-bottom: 5px;
}
.page-content-wrapper .home-top3 .title {
  margin-bottom: 20px;
}
.page-content-wrapper .home-top3 .title .bold {
  font-size: 16px;
}
.page-content-wrapper .home-top3 .content {
  position: relative;
  padding: 15px;
  padding-bottom: 60px;
  height: 184px;
}
.page-content-wrapper .home-top3 .content .md-ct {
  width: 100px;
  display: inline-block;
  width: 100%;
}
.page-content-wrapper .home-top3 .content .md-ct .item {
  float: left;
  width: 20%;
  text-align: center;
}
.page-content-wrapper .home-top3 .content .md-ct .item:first-child {
  width: 40%;
}
.page-content-wrapper .home-top3 .content .md-ct .item .bold {
  font-size: 16px;
}
.page-content-wrapper .home-top3 .content .md-bt {
  position: absolute;
  width: 100%;
  height: 40px;
  left: 0;
  bottom: 0;
  border-top: 1px solid #cdcdcd;
  line-height: 38px;
  text-align: center;
  background: #f3f3f3;
}
.page-content-wrapper .home-top3 .content .title-right {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}
.page-content-wrapper .home-top3 .content .item-right {
  margin-bottom: 20px;
}
.page-content-wrapper .home-top3 .content .item-right:last-child {
  margin-bottom: 0;
}
.page-content-wrapper .home-top3 .content .item-right .right {
  font-size: 22px;
  float: right;
  text-decoration: underline;
}
.page-content-wrapper .home-top3 .content.v2 .item-right {
  margin-bottom: 20px;
  padding-top: 15px;
}
.page-content-wrapper .home-top4 {
  margin-bottom: 40px;
}
.page-content-wrapper .home-top4 .content {
  padding: 15px;
  position: relative;
}
.page-content-wrapper .home-top4 .content .top {
  margin-bottom: 40px;
  border-bottom: 1px solid #cdcdcd;
  padding-bottom: 10px;
}
.page-content-wrapper .home-top4 .content .top .title {
  font-weight: bold;
}
.page-content-wrapper .home-top4 .pana {
  position: absolute;
  width: 200px;
  height: 30px;
  text-align: center;
  right: 20px;
  top: 30px;
}
#popupEditMenu .modal-dialog {
  margin-top: 100px;
}
#popupEditMenu .modal-body .md-title {
  font-weight: bold;
  padding-left: 15px;
  margin-bottom: 10px;
}
#popupEditMenu .modal-body .md-title.v2 {
  padding-left: 35px;
}
#popupEditMenu .modal-body .md-content {
  padding-right: 20px;
  position: relative;
}
#popupEditMenu .modal-body .md-content.v2 {
  padding-right: 0;
  padding-left: 20px;
}
#popupEditMenu .modal-body .md-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: auto;
  display: inline-block;
  width: 100%;
  height: 250px;
}
#popupEditMenu .modal-body .md-content ul li a {
  width: 100px;
  display: inline-block;
  width: 100%;
  line-height: 38px;
  padding-left: 15px;
  font-size: 13px;
  color: #333;
}
#popupEditMenu .modal-body .md-content ul li.noselect {
  position: relative;
}
#popupEditMenu .modal-body .md-content ul li.noselect:before {
  position: absolute;
  content: "";
  cursor: no-drop;
  width: 100%;
  height: 38px;
  top: 0;
  left: 0;
  z-index: 9;
  background: #fff;
  opacity: 0.3;
}
#popupEditMenu .modal-body .md-content ul li:hover a {
  background: #f3f3f3;
  text-decoration: none;
}
#popupEditMenu .modal-body .md-content ul li.activev1 a {
  background: #f3f3f3;
  text-decoration: none;
}
#popupEditMenu .modal-body .md-content ul li.activev2 a {
  background: #f3f3f3;
  text-decoration: none;
}
#popupEditMenu .modal-body .md-content span.icon {
  position: absolute;
  right: -30px;
  top: 100px;
  z-index: 2;
  opacity: 0.6;
  cursor: no-drop;
}
#popupEditMenu .modal-body .md-content span.icon i {
  font-size: 30px;
  font-weight: bold;
}
#popupEditMenu .modal-body .md-content span.icon.active-menu {
  opacity: 1;
  cursor: pointer;
}
#popupEditMenu .modal-body .md-content span.icon.v2 {
  right: inherit;
  left: -30px;
  top: 130px;
}
#popupEditMenu .modal-body .md-content span.icon.disable1 {
  opacity: 0.6;
  cursor: no-drop;
}
#popupEditMenu .modal-body .footer {
  text-align: center;
  margin-top: 40px;
}
.page-content-wrapper .container {
  position: relative;
}
.page-content-wrapper .main-body-content {
  position: relative;
}
.page-content-wrapper .main-body-content .page-bar {
  background: #eeeeee;
  border-bottom: none;
  border-radius: 0;
  margin-bottom: 15px;
}
.page-content-wrapper .main-body-content .page-bar .page-breadcrumb {
  padding-left: 0;
  margin-top: 10px;
  display: inline-block;
  width: 100%;
  padding: 30px 15px 10px 30px;
  margin: 0;
  height: 78px;
}
.page-content-wrapper .main-body-content .page-bar .page-breadcrumb li {
  position: relative;
  padding-right: 15px;
  margin-left: 5px;
}
.page-content-wrapper .main-body-content .page-bar .page-breadcrumb li a img {
  float: left;
  margin-top: 3px;
  margin-right: 5px;
}
.page-content-wrapper .main-body-content .page-bar .page-breadcrumb li:before {
  content: "\f105";
  font-family: FontAwesome;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  right: -4px;
  color: #aaaab1;
  font-size: 16px;
}
.page-content-wrapper .main-body-content .page-bar .page-breadcrumb li:last-child:before {
  display: none;
}
.page-content-wrapper .main-body-content .page-bar .page-breadcrumb li a,
.page-content-wrapper .main-body-content .page-bar .page-breadcrumb li span {
  font-size: 18px;
  color: #aaaab1;
}
.page-content-wrapper .main-body-content .page-bar .page-breadcrumb li a:hover,
.page-content-wrapper .main-body-content .page-bar .page-breadcrumb li span:hover {
  color: #666;
}
.page-content-wrapper .main-body-content .page-bar .page-breadcrumb li a:hover i,
.page-content-wrapper .main-body-content .page-bar .page-breadcrumb li span:hover i {
  color: #666;
}
.page-content-wrapper .main-body-content .page-bar .page-breadcrumb li i {
  font-size: 16px;
  color: #666;
}
.page-content-wrapper .main-body-content .main-page {
  padding-left: 50px;
  padding-right: 50px;
}
.page-content-wrapper .main-body-content .main-page .title-page {
  border-bottom: 1px solid #d9d8d8;
  padding-top: 20px;
  padding-bottom: 10px;
}
.page-content-wrapper .main-body-content .main-page .title-page h3 {
  font-size: 32px;
  color: #333;
  font-family: "YuGothicBold", sans-serif;
}
.page-content-wrapper .main-body-content .main-page .top-page .full {
  position: relative;
  font-size: 16px;
}
.page-content-wrapper .main-body-content .main-page .top-page .full.pdl-col-left {
  padding-left: 120px;
}
.page-content-wrapper .main-body-content .main-page .top-page .full.pdl-col-left .col-left {
  position: absolute;
  width: 120px;
  top: 0;
  left: 0;
}
.page-content-wrapper .main-body-content .main-page .top-page .full.pdl-col-left .col-right {
  display: inline-block;
  width: 100%;
}
.page-content-wrapper
  .main-body-content
  .main-page
  .top-page
  .full.pdl-col-left
  .col-right
  .item-right {
  padding: 0 15px;
}
.page-content-wrapper
  .main-body-content
  .main-page
  .top-page
  .full.pdl-col-left
  .col-right
  .item-right
  .item-col-left {
  position: relative;
}
.page-content-wrapper
  .main-body-content
  .main-page
  .top-page
  .full.pdl-col-left
  .col-right
  .item-right
  .item-col-left.col-left-100 {
  padding-left: 100px;
}
.page-content-wrapper
  .main-body-content
  .main-page
  .top-page
  .full.pdl-col-left
  .col-right
  .item-right
  .item-col-left.col-left-200 {
  padding-left: 200px;
}
.page-content-wrapper
  .main-body-content
  .main-page
  .top-page
  .full.pdl-col-left
  .col-right
  .item-right
  .item-col-left.col-left-40 {
  padding-left: 40px;
}
.page-content-wrapper
  .main-body-content
  .main-page
  .top-page
  .full.pdl-col-left
  .col-right
  .item-right
  .item-col-left
  .ct-col-left {
  position: absolute;
  top: 0;
  left: 0;
  text-align: right;
  padding-right: 30px;
}
.page-content-wrapper
  .main-body-content
  .main-page
  .top-page
  .full.pdl-col-left
  .col-right
  .item-right
  .item-col-left
  .ct-col-left.left-100 {
  width: 100px;
}
.page-content-wrapper
  .main-body-content
  .main-page
  .top-page
  .full.pdl-col-left
  .col-right
  .item-right
  .item-col-left
  .ct-col-left.left-200 {
  width: 200px;
}
.page-content-wrapper
  .main-body-content
  .main-page
  .top-page
  .full.pdl-col-left
  .col-right
  .item-right
  .item-col-left
  .ct-col-left.left-40 {
  width: 40px;
}
.page-content-wrapper .main-body-content .main-page .top-page .full.pdl-col-left.left-150 {
  padding-left: 150px;
}
.page-content-wrapper
  .main-body-content
  .main-page
  .top-page
  .full.pdl-col-left.left-150
  .col-left {
  position: absolute;
  width: 150px;
  top: 0;
  left: 0;
}
.page-content-wrapper .sidebar-left {
  position: fixed;
  width: 250px;
  height: 100%;
  left: 0px;
  top: 0;
  background: #222d32;
  z-index: 8;
}
.page-content-wrapper .sidebar-left .page-sidebar {
  margin: 0;
  background: none;
  width: 100%;
}
.page-content-wrapper .sidebar-left .page-sidebar .page-sidebar-menu li:hover a,
.page-content-wrapper .sidebar-left .page-sidebar .page-sidebar-menu li.active a {
  background: #252d37;
}
.page-content-wrapper .sidebar-left .page-sidebar .page-sidebar-menu li a {
  border-bottom: 1px solid #3d4957;
  border-top: none;
}
.select2-container .select2-choice .select2-arrow {
  width: 25px;
  padding-left: 4px;
}
.select2-container {
  float: left;
  width: 200px;
}
.modal-header {
  position: relative;
}
.modal-header .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.page-content-wrapper .member-page .member-main-top {
  padding: 20px;
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.page-content-wrapper .member-page .member-main-top .item-top {
  position: relative;
  padding-left: 150px;
  margin-bottom: 10px;
}
.page-content-wrapper .member-page .member-main-top .item-top .left {
  position: absolute;
  width: 150px;
  color: #222;
  line-height: 40px;
  top: 0;
  left: 0;
}
.page-content-wrapper .member-page .member-main-top .item-top .right {
  display: inline-block;
  width: 100%;
}
.page-content-wrapper .member-page .member-main-top .item-top .right .item {
  float: left;
  margin-right: 40px;
  position: relative;
}
.page-content-wrapper .member-page .member-main-top .item-top .right .item span {
  display: inline-block;
}
.page-content-wrapper .member-page .member-main-top .item-top .right .item input.date-picker {
  width: 155px !important;
}
.page-content-wrapper .member-page .member-main-top .item-top .right .item.icon-next:before {
  position: absolute;
  width: 40px;
  height: 40px;
  content: "\f0ec";
  top: 0;
  font-family: FontAwesome;
  right: -50px;
  line-height: 34px;
}
.page-content-wrapper .member-page .member-main-top .item-top .right .item .checkbox-list {
  float: left;
  min-width: 183px;
}
.page-content-wrapper .member-page .member-main-top .item-top .right .item .checkbox-list label {
  float: left;
  line-height: 34px;
  margin-right: 20px;
  margin-bottom: 0;
}
.page-content-wrapper
  .member-page
  .member-main-top
  .item-top
  .right
  .item
  .checkbox-list
  label
  .checker {
  margin: 0;
}
.page-content-wrapper .member-page .member-main-top .item-top .right .item.style2 .title {
  float: left;
  width: 60px;
}
.page-content-wrapper .member-page .member-main-top .item-top.stylev2 .left {
  padding-top: 10px;
}
.page-content-wrapper .member-page .member-main-top .item-top.stylev2.v2 .left {
  padding-top: 0;
}
.page-content-wrapper .member-page .member-main-top .item-top.stylev2.v2 .right .item:first-child {
  padding-top: 0;
}
.page-content-wrapper .member-page .member-main-top .item-top.stylev2.v2 .right .item .title {
  padding-top: 0;
  line-height: 36px;
}
.page-content-wrapper .member-page .member-main-top .item-top.stylev2 .right .item:first-child {
  padding-top: 13px;
  width: 250px;
}
.page-content-wrapper .member-page .member-main-top .item-top.stylev2 .right .item .title {
  padding-top: 20px;
}
.page-content-wrapper .member-page .link-submit {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
  float: left;
  width: 100%;
}
.page-content-wrapper .member-page .link-submit button {
  text-align: center;
  padding: 0 40px;
  line-height: 40px;
}
.page-content-wrapper .member-page .table-member-mng p {
  margin: 0;
}
.page-content-wrapper .member-page .table-member-mng th {
  padding-left: 20px;
  padding-right: 20px;
}
.page-content-wrapper .member-page .table-member-mng td {
  color: #7e7e7e;
  padding-left: 20px;
  padding-right: 20px;
}
.page-content-wrapper .member-page .member-title-table {
  width: 100px;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}
.page-content-wrapper .member-page .member-title-table .md-ct > span {
  margin-right: 10px;
  display: inline-block;
  float: left;
  font-size: 16px;
  margin-top: 10px;
}
.page-content-wrapper .member-page .member-title-table .md-ct > span.title {
  font-weight: bold;
}
.page-content-wrapper .member-main-content {
  position: relative;
}
.page-content-wrapper .member-main-content .tabbable-custom {
  position: relative;
}
.page-content-wrapper .member-main-content .tabbable-custom .link-top {
  position: absolute;
  padding: 0 40px;
  line-height: 40px;
  top: 0px;
  right: 20px;
}
.page-content-wrapper .member-main-content .tab-content {
  margin-bottom: 20px;
}
.page-content-wrapper .member-main-content .tab-content .portlet-body {
  padding-bottom: 50px;
}
.page-content-wrapper .member-main-content .tab-content .tabv2 .member-title-table {
  margin-bottom: 10px;
}
.page-content-wrapper .member-main-content .tab-content .tabv2 .member-title-table span {
  font-size: 14px;
}
.page-content-wrapper .member-main-content .tab-content .tabv2 .table-member-mng {
  padding: 0 10px;
}
.page-content-wrapper .member-main-content .tab-content .tabv2 .table-member-mng thead {
  background: none;
}
.page-content-wrapper .member-main-content .tab-content .bootstrap-select {
  min-width: 60px;
}
.page-content-wrapper .member-main-content .tab-content .bootstrap-select span {
  line-height: 30px !important;
}
.page-content-wrapper .member-main-content .tab-content .bootstrap-select .dropdown-toggle {
  padding: 0 10px;
  height: 34px;
}
.page-content-wrapper .member-main-content .tab-content .bootstrap-select .caret {
  top: 10px;
  right: 7px;
}
.page-content-wrapper .member-main-content .tab-content .bootstrap-select .dropdown-menu {
  box-shadow: none;
}
.page-content-wrapper .member-main-content .tab-content .bootstrap-select .dropdown-menu:after,
.page-content-wrapper .member-main-content .tab-content .bootstrap-select .dropdown-menu:before {
  display: none !important;
}
.page-content-wrapper .member-main-content .tab-content .bootstrap-select .dropdown-menu ul li a {
  line-height: 30px;
  padding: 0;
  text-align: center;
}
.page-content-wrapper .member-main-content .tab-content .all-select {
  margin-right: -30px;
}
.page-content-wrapper .member-main-content .tab-content .all-select > div {
  position: relative;
}
.page-content-wrapper .member-main-content .tab-content .all-select > div .note {
  position: absolute;
  top: -3px;
  right: -5px;
  line-height: 40px;
  border: none;
  padding: 0;
}
.page-content-wrapper .member-main-content .tab-content .portlet.box {
  position: relative;
}
.page-content-wrapper .member-main-content .tab-content .portlet.box:before {
  position: absolute;
  background: #cdcdcd;
  width: 80%;
  left: 10%;
  content: "";
  height: 1px;
  bottom: 80px;
}
.page-content-wrapper .member-main-content .tab-content .member-title-table .btn {
  margin-left: 15px;
}
.page-content-wrapper .member-main-content .tab-content .member-title-table .md-content {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
}
.page-content-wrapper .member-main-content .tab-content .member-title-table .md-ct {
  padding-left: 15px;
}
.page-content-wrapper .member-main-content .tab-content .portlet-footer {
  display: block;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 30px;
}
.page-content-wrapper .member-main-content .tab-content .portlet-footer .btn {
  padding: 0 40px;
  line-height: 40px;
}
.page-content-wrapper .member-main-content .tab-content .form-group {
  margin-bottom: 10px;
  padding: 0 30px;
}
.page-content-wrapper .member-main-content .tab-content .form-group:last-child {
  margin-bottom: 0;
}
.page-content-wrapper .member-main-content .tab-content .form-group .right,
.page-content-wrapper .member-main-content .tab-content .form-group .left {
  float: left;
  width: 50%;
  margin: 0;
}
.page-content-wrapper .member-main-content .tab-content .form-group .right label,
.page-content-wrapper .member-main-content .tab-content .form-group .left label {
  padding: 0;
  line-height: 40px;
  text-align: left;
}
.page-content-wrapper .member-main-content .tab-content .form-group .right .ct span,
.page-content-wrapper .member-main-content .tab-content .form-group .left .ct span {
  line-height: 40px;
}
.page-content-wrapper .member-main-content .tab-content .form-group .right .ct.v2 span,
.page-content-wrapper .member-main-content .tab-content .form-group .left .ct.v2 span {
  line-height: 30px;
  margin-right: 3px;
  display: inline-block;
}
.page-content-wrapper .member-main-content .tab-content .form-group .radio-list label {
  float: left;
  margin-right: 20px;
  font-size: 13px;
}
.page-content-wrapper .member-main-content .tab-content .form-group .radio-list label .form-group {
  float: left;
  width: 30px;
}
.page-content-wrapper .member-main-content .tab-content .form-group .radio-list .radio {
  float: left;
  margin-right: 3px;
  margin-top: 5px;
  padding: 0;
  margin-left: 0;
}
.page-content-wrapper .member-main-content .tab-content .form-group .history-ban-acc {
  margin-left: 10px;
}
.page-content-wrapper .member-main-content .tab-content .form-group .left.v2 {
  width: 100%;
}
.page-content-wrapper .member-main-content .tab-content .form-group .left.v2 p {
  line-height: 40px;
}
.page-content-wrapper .member-main-content .tab-content .form-title {
  display: block;
  margin-bottom: 20px;
}
.page-content-wrapper .member-main-content .tab-content .form-title .title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  line-height: 40px;
}
.page-content-wrapper .member-main-content .tab-content .form-title a {
  margin-left: 10px;
  padding: 0 30px;
  line-height: 34px;
}
.page-content-wrapper.countselor .item-count {
  width: 100px;
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.page-content-wrapper.countselor .item-count .item-right,
.page-content-wrapper.countselor .item-count .item-left {
  float: left;
  width: 50%;
  position: relative;
  padding-left: 120px;
}
.page-content-wrapper.countselor .item-count .item-right .checkbox-list .checker,
.page-content-wrapper.countselor .item-count .item-left .checkbox-list .checker {
  margin: 0;
  margin-left: -2px;
}
.page-content-wrapper.countselor .item-count .item-right .checkbox-list label,
.page-content-wrapper.countselor .item-count .item-left .checkbox-list label {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
}
.page-content-wrapper.countselor .item-count .item-right .checkbox-list label:last-child,
.page-content-wrapper.countselor .item-count .item-left .checkbox-list label:last-child {
  margin-right: 0;
}
.page-content-wrapper.countselor .item-count span.title {
  position: absolute;
  width: 120px;
  line-height: 34px;
  text-align: left;
  top: 0;
  left: 0;
}
.page-content-wrapper.countselor .item-count .select-option {
  max-width: 150px;
}
.page-content-wrapper.countselor .item-count .countselor-list-popup {
  float: right;
  margin-top: -3px;
}
.page-content-wrapper.countselor .item-count .bootstrap-select .dropdown-menu ul li a {
  text-align: left;
  padding-left: 15px;
}
.page-content-wrapper.countselor .item-count.stylev2 .item-right span.title,
.page-content-wrapper.countselor .item-count.stylev2 .item-left span.title {
  top: 10px;
}
.page-content-wrapper.countselor .item-count.stylev2 .item-right .left-ct {
  padding-top: 18px;
}
.page-content-wrapper.countselor .item-count.stylev2 .item-right label {
  margin-bottom: 10px;
}
.page-content-wrapper.countselor .link-dowload {
  position: relative;
}
.page-content-wrapper.countselor .link-dowload .btn {
  position: absolute;
  top: 10px;
  right: 0;
}
.page-content-wrapper.countselor .business .item-count .checkbox-list label {
  margin-bottom: 0;
}
.page-content-wrapper.countselor .business .item-count {
  margin-bottom: 0;
}
.page-content-wrapper.countselor .business .item-count .left-ct {
  padding-top: 8px;
}
.page-content-wrapper.countselor .business .item-count .item-right .select-option {
  float: left;
}
.page-content-wrapper.countselor
  .business
  .item-count
  .item-right
  .select-option
  .bootstrap-select {
  width: 100px;
  min-width: 100px;
}
.page-content-wrapper.countselor .business .item-count .item-right .right-ct input {
  float: left;
  width: 200px;
  margin-left: 15px;
}
.page-content-wrapper.countselor .business .member-title-table .bootstrap-select {
  width: 200px;
}
.page-content-wrapper.countselor .business .member-title-table a.link-bussine-member {
  float: right;
  padding: 0 40px;
  margin-left: 15px;
  line-height: 34px;
}
.page-content-wrapper.countselor
  .business
  .member-title-table
  .bootstrap-select.btn-group
  .dropdown-menu
  li
  a {
  text-align: left;
  padding-left: 10px;
}
.page-content-wrapper.business-detail .member-main-content .tab-content .portlet.box:before {
  bottom: 120px;
}
.page-content-wrapper.business-detail .form-footer {
  margin-top: 60px;
  padding: 0 15px;
}
.page-content-wrapper.business-detail .form-footer .btn {
  line-height: 40px;
  padding: 0 40px;
}
.page-content-wrapper.business-detail
  .member-main-content
  .tab-content
  .bootstrap-select
  .dropdown-menu
  ul
  li
  a {
  text-align: left;
  padding-left: 10px;
}
.member-sub.show-item1 .item2 {
  display: none;
}
.member-sub.show-item1 .item1 {
  display: inline-block;
}
.member-sub.show-item2 .item2 {
  display: inline-block;
}
.member-sub.show-item2 .item1 {
  display: none;
}
.detail-sub .form-title .title {
  margin-bottom: 15px;
  display: inline-block;
}
.detail-sub .portlet-body {
  padding-bottom: 10px !important;
}
.detail-sub .list-radio label {
  display: inline-block;
}
.detail-sub .list-radio .radio {
  margin: 0;
  padding: 0;
  line-height: 32px;
}
.detail-sub .list-radio .radio span {
  margin: 0;
  padding: 0;
}
.detail-sub .bootstrap-select .dropdown-menu ul li a {
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 10px !important;
  text-align: left !important;
}
.detail-sub .titlev2 {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 50px;
  display: inline-block;
  width: 100%;
  padding-bottom: 10px;
  position: relative;
}
.detail-sub .titlev2:before {
  position: absolute;
  content: "";
  background: #cdcdcd;
  width: 500px;
  height: 1px;
  bottom: 0;
  left: 0;
}
.detail-sub .form-title .title {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 3px;
}
.detail-sub .form-title .title:before {
  position: absolute;
  content: "";
  background: #cdcdcd;
  width: 500px;
  height: 1px;
  bottom: 0;
  left: 0;
}
.detail-sub .portlet:before {
  display: none;
}
.show-radiobusiness-detail .show-itemradio2 {
  display: none;
}
.show-radiobusiness-detail.show-item2 .show-itemradio2 {
  display: inline-block;
}
.show-radiobusiness-detail.show-item2 .show-itemradio1 {
  display: none;
}
.show-radiobusiness-detail.show-item1 .show-itemradio1 {
  display: inline-block;
}
.show-radiobusiness-detail.show-item1 .show-itemradio2 {
  display: none;
}
.page-content-wrapper.countselor.request-list
  .business
  .item-count
  .item-right
  .select-option.full
  .bootstrap-select {
  width: 250px;
}
.page-content-wrapper.countselor.request-list
  .business
  .item-count
  .item-right
  .select-option
  .bootstrap-select {
  width: 130px;
}
.page-content-wrapper.countselor.request-list .table-member-mng .img {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: inline-block;
  margin-top: -10px;
}
.page-content-wrapper.countselor.request-list .table-member-mng .img img {
  max-width: 100%;
}
.table-member-mng {
  padding-bottom: 50px;
}
.page-content-wrapper.countselor.request-list .table-member-mng tbody tr td {
  padding-top: 20px;
}
.page-content-wrapper.countselor.request-list .item-table {
  position: relative;
  margin-bottom: 60px;
}
.page-content-wrapper.countselor.request-list .item-table .table-bottom {
  position: absolute;
  width: 100%;
  height: 40px;
  line-height: 38px;
  border: 1px solid #cdcdcd;
  border-top: none;
  left: 0;
  bottom: -40px;
  padding-left: 15px;
}
.page-content-wrapper.countselor.request-list .item-table .table-bottom i {
  font-size: 18px;
}
.page-content-wrapper.countselor.request-list .item-table .table-bottom span {
  margin-left: 5px;
}
.sidebar-menu .sidebar-submenu > li > a {
  line-height: 36px;
}
@media screen and (max-width: 1500px) {
  .preview-mobile .preview .form-body {
    padding-left: 180px;
  }
}
.top-menu .dropdown-menu {
  width: 300px;
  padding: 15px 20px;
}
.top-menu .dropdown-menu .item-menu {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.top-menu .dropdown-menu .item-menu span {
  line-height: 30px;
  display: inline-block;
}
.top-menu .dropdown-menu .item-menu .title {
  float: left;
  width: 120px;
  text-align: left;
}
.sidebar-left .sidebar-menu > li.active > a {
  margin-bottom: 0;
}
.sidebar-menu > li {
  float: left;
  width: 100%;
}
.sidebar-menu > li > a {
  margin-bottom: 0 !important;
}
.sidebar-menu > li .sidebar-submenu .sidebar-submenu {
  padding-left: 10px;
}
.slide-map {
  position: relative;
  width: 100px;
  display: inline-block;
  width: 100%;
  padding-bottom: 20px;
}
.slide-map .item-map {
  width: 100px;
  display: inline-block;
  width: 100%;
  position: absolute;
  top: 0;
}
.slide-map .owl-nav > div {
  position: absolute;
  width: 25px;
  height: 25px;
  top: -28px;
  right: 0;
  z-index: 9;
  font-size: 0;
}
.slide-map .owl-nav > div.owl-prev {
  right: 80px;
}
.slide-map .owl-nav > div.owl-prev:before {
  content: "\f0d9";
}
.slide-map .owl-nav > div.owl-next:before {
  content: "\f0da";
}
.slide-map .owl-nav > div:hover:before {
  color: #222;
}
.slide-map .owl-nav > div:before {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  text-align: center;
  font-family: FontAwesome;
  color: #666;
  font-size: 18px;
}
.bootstrap-select.text-left .dropdown-menu ul li a {
  text-align: left;
  padding-left: 12px;
}
.slide-banner .border-full {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.slide-banner .top-content {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 150px;
  border-bottom: 1px solid #cdcdcd;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
.slide-banner .top-content .top-left {
  position: absolute;
  width: 150px;
  top: 0;
  left: 0;
  height: 40px;
  border-right: 1px solid #cdcdcd;
  text-align: center;
}
.slide-banner .main-content {
  float: left;
  width: 100%;
}
.slide-banner .main-content .item-slide {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 150px;
  border-bottom: 1px solid #cdcdcd;
  text-align: center;
}
.slide-banner .main-content .item-slide:last-child {
  border-bottom: none;
}
.slide-banner .main-content .item-slide .main-left {
  position: absolute;
  width: 150px;
  height: 100%;
  top: 0;
  left: 0;
  border-right: 1px solid #cdcdcd;
  text-align: center;
}
.slide-banner .main-content .item-slide .main-left .bootstrap-select {
  width: 80px;
  min-width: 80px;
  margin-top: 50%;
}
.slide-banner .main-content .item-slide .main-left .bootstrap-select .dropdown-toggle {
  width: 80px;
  border-radius: 0;
}
.slide-banner .main-content .item-slide .main-right {
  text-align: left;
  padding: 20px 30px;
  float: left;
  width: 100%;
}
.slide-banner .main-content .item-slide .main-right .inputfile,
.slide-banner .main-content .item-slide .main-right .image-upload,
.slide-banner .main-content .item-slide .main-right .item-upload {
  float: left;
  width: 100%;
}
.slide-banner .main-content .item-slide .main-right .preview-image {
  float: left;
  width: 400px;
  position: relative;
}
.slide-banner .main-content .item-slide .main-right .preview-image .content-input {
  position: absolute;
  left: 105%;
  bottom: 0;
  width: 600px;
}
.slide-banner .main-content .item-slide .main-right .preview-image .content-input .btn-select-file {
  float: left;
}
.slide-banner .main-content .item-slide .main-right .preview-image .content-input .input-icon {
  float: left;
  margin-left: 10px;
  padding-top: 5px;
  width: 500px;
}
.intro-pageform .main-content {
  width: 100px;
  display: inline-block;
  width: 100%;
  padding-top: 10px;
}
.intro-pageform .main-content .preview-image {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.intro-pageform .main-content .preview-image img {
  width: 100%;
}
.intro-pageform .main-content .btn-select-file {
  float: left;
  margin-right: 5px;
  margin-top: 10px;
}
.intro-pageform .main-content .input-icon {
  float: left;
  width: 200px !important;
  position: relative;
  overflow: hidden;
  padding-top: 5px;
}
.intro-pageform .main-content .show-file-info {
  float: left;
  margin-top: 10px;
}
.intro-pageform .main-note {
  margin-top: 30px;
  padding-left: 15px;
}
.total-select0 .dropdown-menu ul li {
  display: none;
}
.total-select0 .dropdown-menu ul li:nth-child(2) {
  display: block;
}
.total-select1 .dropdown-menu ul li {
  display: none;
}
.total-select1 .dropdown-menu ul li:nth-child(2),
.total-select1 .dropdown-menu ul li:nth-child(3) {
  display: block;
}
.total-select2 .dropdown-menu ul li {
  display: none;
}
.total-select2 .dropdown-menu ul li:nth-child(2),
.total-select2 .dropdown-menu ul li:nth-child(3),
.total-select2 .dropdown-menu ul li:nth-child(4) {
  display: block;
}
.total-select3 .dropdown-menu ul li {
  display: none;
}
.total-select3 .dropdown-menu ul li:nth-child(2),
.total-select3 .dropdown-menu ul li:nth-child(3),
.total-select3 .dropdown-menu ul li:nth-child(4),
.total-select3 .dropdown-menu ul li:nth-child(5) {
  display: block;
}
.page-content-wrapper .main-body-content.mind-page .member-title-table .option-mind {
  margin-top: 5px;
}
.page-content-wrapper
  .main-body-content.mind-page
  .member-title-table
  .option-mind
  .bootstrap-select.btn-group
  .dropdown-menu
  li
  a {
  text-align: left;
  padding-left: 15px;
}
.page-content-wrapper .main-body-content.mind-page .member-title-table .mind-popup {
  float: right;
  padding: 0 30px;
  line-height: 32px;
}
.page-content-wrapper .main-body-content.mind-page .link-submit button {
  margin-top: 40px;
}
.page-content-wrapper .main-body-content.mind-page .member-main-top {
  float: left;
  width: 100%;
}
.page-content-wrapper .main-body-content.mind-page .member-main-top .content-mind {
  float: left;
  width: 100%;
}
.page-content-wrapper .main-body-content.mind-page .member-main-top .content-mind .main-item-mind {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 100px;
  border-bottom: 1px solid #cdcdcd;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.page-content-wrapper
  .main-body-content.mind-page
  .member-main-top
  .content-mind:last-child
  .main-item-mind {
  border-bottom: none;
}
.page-content-wrapper .main-body-content.mind-page .member-main-top .content-mind .number {
  position: absolute;
  width: 100px;
  height: 30px;
  text-align: center;
  top: 50%;
  margin-top: -15px;
  left: 0;
  font-size: 14px;
  font-weight: bold;
}
.page-content-wrapper .main-body-content.mind-page .member-main-top .content-mind > input {
  width: 800px;
}
.page-content-wrapper .main-body-content.mind-page .member-main-top .content-mind .item-option {
  float: left;
  width: 100%;
  margin-top: 20px;
}
.page-content-wrapper
  .main-body-content.mind-page
  .member-main-top
  .content-mind
  .item-option
  .bootstrap-select {
  float: left !important;
  width: 240px;
  margin-right: 20px;
}
.page-content-wrapper
  .main-body-content.mind-page
  .member-main-top
  .content-mind
  .item-option
  .bootstrap-select
  > button {
  border-radius: 0;
}
.page-content-wrapper
  .main-body-content.mind-page
  .member-main-top
  .content-mind
  .item-option
  .bootstrap-select
  ul
  li
  a {
  text-align: left;
  padding-left: 10px;
}
.page-content-wrapper .main-body-content.mind-page .member-main-top .content-mind .item-in-ct {
  float: left;
  width: 100%;
  margin-top: 20px;
  position: relative;
  padding-left: 40px;
  margin-bottom: 15px;
}
.page-content-wrapper
  .main-body-content.mind-page
  .member-main-top
  .content-mind
  .item-in-ct:before {
  position: absolute;
  width: 30px;
  height: 32px;
  top: 6px;
  text-align: center;
  left: 0;
  content: "\f0c5";
  font-family: FontAwesome;
}
.page-content-wrapper
  .main-body-content.mind-page
  .member-main-top
  .content-mind
  .item-in-ct
  input {
  float: left;
}
.page-content-wrapper
  .main-body-content.mind-page
  .member-main-top
  .content-mind
  .item-in-ct
  input.v1 {
  width: 620px;
  margin-right: 20px;
}
.page-content-wrapper
  .main-body-content.mind-page
  .member-main-top
  .content-mind
  .item-in-ct
  input.v2 {
  width: 120px;
}
.page-content-wrapper .main-body-content.push-list .tooltip .tooltip-inner {
  padding: 10px !important;
}
.page-content-wrapper .main-body-content.push-list .tooltip .tooltip-inner p {
  text-align: left;
}
.page-content-wrapper .main-body-content.push-list td {
  vertical-align: middle;
}
.page-content-wrapper .main-body-content.push-list .border p:first-child {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 5px;
  padding-bottom: 5px;
}
#slide-push {
  margin-top: 100px;
  float: left;
  width: 100%;
}
#slide-push .owl-carousel {
  float: left;
  width: 100%;
}
#slide-push .owl-carousel .owl-wrapper-outer {
  float: left;
  width: 100%;
  overflow: hidden;
}
#slide-push .owl-controls {
  position: absolute;
  width: 100%;
  height: 50px;
  top: -80px;
  left: 0;
}
#slide-push .owl-controls .owl-pagination {
  position: absolute;
  width: 600px;
  height: 54px;
  top: 0;
  left: 50%;
  margin-left: -300px;
}
#slide-push .owl-controls .owl-pagination .owl-page {
  float: left;
  position: relative;
  width: 200px;
  height: 60px;
  cursor: pointer;
  transition: all 0.5s ease;
}
#slide-push .owl-controls .owl-pagination .owl-page:hover:before,
#slide-push .owl-controls .owl-pagination .owl-page.active:before {
  background: url(../images/icon/carounext.jpg) no-repeat;
}
#slide-push .owl-controls .owl-pagination .owl-page:hover:after,
#slide-push .owl-controls .owl-pagination .owl-page.active:after {
  color: #50b7f0;
}
#slide-push .owl-controls .owl-pagination .owl-page:hover span,
#slide-push .owl-controls .owl-pagination .owl-page.active span {
  background: url(../images/icon/iconpanaslide2.jpg) no-repeat;
}
#slide-push .owl-controls .owl-pagination .owl-page:before {
  content: "";
  background: url(../images/icon/carounext2.jpg) no-repeat;
  position: absolute;
  width: 6px;
  height: 12px;
  top: 13px;
  right: -3px;
  transition: all 0.5s ease;
}
#slide-push .owl-controls .owl-pagination .owl-page:after {
  position: absolute;
  width: 200px;
  height: 40px;
  bottom: 0;
  left: 0;
  font-size: 13px;
  color: #8191a1;
  line-height: 18px;
  text-align: center;
  transition: all 0.5s ease;
  content: "2단계: 대상자 확인";
}
#slide-push .owl-controls .owl-pagination .owl-page:last-child:before {
  display: none;
}
#slide-push .owl-controls .owl-pagination .owl-page:last-child:after {
  content: "3단계: 문구 작성";
}
#slide-push .owl-controls .owl-pagination .owl-page:last-child span {
  margin-left: -17px;
  width: 34px;
}
#slide-push .owl-controls .owl-pagination .owl-page:first-child:after {
  content: "1단계: 푸시 대상 조회";
}
#slide-push .owl-controls .owl-pagination .owl-page:first-child span {
  margin-left: -4px;
  width: 8px;
}
#slide-push .owl-controls .owl-pagination .owl-page span {
  position: absolute;
  width: 23px;
  transition: all 0.5s ease;
  height: 8px;
  top: 5px;
  left: 50%;
  margin-left: -12px;
  background: url(../images/icon/iconpanaslide.jpg) no-repeat;
  background-position: 0px 0;
}
#slide-push .top-tab {
  position: absolute;
  top: 5px;
  right: 0;
  border: 1px solid #cdcdcd;
  width: 170px;
  height: 32px;
  text-align: center;
  padding-top: 5px;
}
#slide-push .top-tab.active {
  background: #02b2d6;
  color: #fff;
  border-color: #02b2d6;
}
#slide-push #tab-none .form-body {
  position: relative;
}
#slide-push #tab-none .form-body:before {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  content: "";
  background: #888;
  opacity: 0.3;
  display: none;
}
#slide-push #tab-none.active .form-body:before {
  display: inline-block;
}
#slide-push .tab-content td {
  vertical-align: middle;
}
#slide-push .tab-content .portlet:before {
  display: none;
}
#slide-push .tab-content .form-body {
  float: left;
  width: 100%;
}
#slide-push .tab-content .form-body .form-group {
  float: left;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 5px;
}
#slide-push .tab-content .form-body .checkbox-list > label {
  float: left;
  margin-right: 20px;
}
#slide-push .tab-content .form-body p {
  margin-bottom: 0;
}
.payment-list .total-all {
  padding: 15px 20px;
  padding-left: 150px;
  position: relative;
}
.payment-list .total-all .avatar {
  position: absolute;
  width: 110px;
  height: 110px;
  background: #e5e5e5;
  top: 20px;
  left: 20px;
  text-align: center;
  line-height: 100px;
}
.payment-list .total-all button {
  position: absolute;
  width: 100px;
  bottom: 20px;
  right: 20px;
}
.page-sidebar-menu {
  padding: 0;
}
.massage-title {
  width: 100px;
  display: inline-block;
  width: 100%;
}
.massage-sending .item-massage {
  width: 100px;
  display: inline-block;
  width: 100%;
  position: relative;
  padding-left: 150px;
}
.massage-sending .item-massage .left {
  position: absolute;
  width: 150px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  left: 0;
  top: 50%;
  margin-top: -15px;
}
.massage-sending .item-massage .right {
  width: 100px;
  display: inline-block;
  width: 100%;
}
.massage-sending .item-massage .right .item-row {
  width: 100px;
  display: inline-block;
  width: 100%;
  padding-left: 15px;
}
.massage-sending .item-massage .right .item-row .add-massage {
  margin-bottom: 10px;
}
.popupcontentfile .inputfile {
  width: 100px;
  display: inline-block;
  width: 100%;
}
.popupcontentfile .inputfile .tr-file-upload,
.popupcontentfile .inputfile .all-file-upload {
  width: 100px;
  display: inline-block;
  width: 100%;
}
.popupcontentfile .inputfile .tr-file-upload .show-file-info,
.popupcontentfile .inputfile .all-file-upload .show-file-info {
  float: left;
  width: 450px;
  border: 1px solid #cdcdcd;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
}
#popupaddmassage .modal-body .modal-list {
  position: relative;
  overflow-x: auto;
  max-height: 450px;
  display: inline-block;
  width: 100%;
}
.time-menu {
  float: right;
  padding-top: 22px;
  margin-right: 30px;
}
.dowload-form {
  position: absolute;
  bottom: 0px;
  right: 0;
}
.rc-pagination {
  justify-content: center;
  margin-top: 20px;
}
.list_contract {
  padding-top: 30px !important;
}
.list_contract .list_contract_top {
  border: 2px solid #cdcdcd;
  margin-bottom: 30px;
  padding: 20px 30px;
  float: left;
  width: 100%;
}
.list_contract .list_contract_top .contract-note {
  float: left;
}
.list_contract .list_contract_top .contract-note p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}
.list_contract .list_contract_top .contract-setting {
  float: right;
}
.list_contract .list_contract_top .contract-setting ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.list_contract .list_contract_top .contract-setting ul li {
  max-width: 32px;
  display: inline-block;
  margin: 0 10px;
  transition: all 0.3s;
}
.list_contract .setting_contract_button {
  float: left;
}
.list_contract .setting_contract_button ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.list_contract .setting_contract_button ul li {
  max-width: 32px;
  display: inline-block;
  margin: 0 10px;
  transition: all 0.3s;
}
.list_contract .contract_search .input-search i {
  margin-top: -4px;
}
.list_contract .contract_search .input-search input {
  border-radius: 4px !important;
}
.list_contract .contract_content {
  display: inline-block;
  width: 100%;
}
.list_contract .contract_content .contract_content_tool {
  display: inline-block;
  width: 100%;
}
.list_contract .contract_content_main {
  margin-top: 20px;
}
.list_contract .contract_content_main ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  float: left;
  width: 100%;
}
.list_contract .contract_content_main ul li {
  width: 100%;
  float: left;
}
.list_contract .contract_content_main ul li:nth-child(1) {
  font-weight: bold;
  font-size: 16px;
}
.list_contract .contract_content_main ul li:nth-child(1) ul li {
  height: 60px;
  vertical-align: middle;
}
.list_contract .contract_content_main ul li .item-content li {
  float: left;
  width: auto;
  text-align: center;
  padding: 8px 10px;
  font-size: 14px;
  min-height: 60px;
  border-bottom: 1px solid #d4d1d1;
  vertical-align: middle;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.list_contract .contract_content_main ul li .item-content li label {
  margin-bottom: 0;
}
.list_contract .contract_content_main ul li .item-content li:nth-child(1) {
  width: 5%;
}
.list_contract .contract_content_main ul li .item-content li:nth-child(2) {
  width: 10%;
}
.list_contract .contract_content_main ul li .item-content li:nth-child(3) {
  width: 20%;
  text-align: left;
}
.list_contract .contract_content_main ul li .item-content li:nth-child(3) span {
  line-height: 18px;
}
.list_contract .contract_content_main ul li .item-content li:nth-child(4) {
  width: 20%;
}
.list_contract .contract_content_main ul li .item-content li:nth-child(5) {
  width: 20%;
}
.list_contract .contract_content_main ul li .item-content li:nth-child(6) {
  width: 10%;
}
.list_contract .contract_content_main ul li .item-content li:nth-child(6) .btn {
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
}
.list_contract .contract_content_main ul li .item-content li:nth-child(7) {
  width: 15%;
}
.list_contract .contract_content_main ul li .item-content li span {
  display: inline-block;
  width: 100%;
}
.list_contract .contract_content_main ul li .item-content li span.number {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid #cdcdcd;
  margin-right: 3px;
  line-height: 24px;
}
.list_contract .contract_content_main.list_contract_grid .list-content {
  width: 108%;
  margin-left: -4%;
}
.list_contract .contract_content_main.list_contract_grid .list-content .item-title {
  display: none;
}
.list_contract .contract_content_main.list_contract_grid .list-content .item {
  float: left;
  width: 25%;
  margin-left: 4%;
  margin-right: 4%;
  padding-left: 25px;
  position: relative;
  margin-bottom: 30px;
}
.list_contract .contract_content_main.list_contract_grid .list-content .item .item-content {
  border: 1px solid #cdcdcd;
  background: #dae8fc;
  position: relative;
  height: 150px;
}
.list_contract .contract_content_main.list_contract_grid .list-content .item .item-content:before {
  position: absolute;
  width: 100%;
  height: 1px;
  content: "";
  background: #cdcdcd;
  bottom: 40px;
  left: 0;
  z-index: 9;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li
  input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: -25px;
  z-index: 9;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(2) {
  position: absolute;
  width: 50px;
  background: #d5e8d4;
  height: 42px;
  bottom: -1px;
  left: 0;
  text-align: center;
  padding: 0;
  min-height: 40px;
  z-index: 8;
  line-height: 42px;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(3) {
  position: absolute;
  width: 100%;
  background: #fff2cc;
  height: 42px;
  bottom: -1px;
  left: 0;
  text-align: left;
  padding-top: 3px;
  padding: 0;
  line-height: 42px;
  min-height: 40px;
  z-index: 7;
  padding-left: 60px;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(3)
  span {
  font-size: 12px;
  padding-right: 76px;
  line-height: 18px;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(7) {
  position: absolute;
  width: 25px;
  background: none;
  height: 25px;
  bottom: 6px;
  right: 5px;
  text-align: center;
  padding: 0;
  min-height: 30px;
  z-index: 9;
  line-height: 42px;
  border: none;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(7)
  img {
  max-width: 100%;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(7)
  a {
  text-decoration: none;
  border: none;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(6)
  .btn {
  position: absolute;
  width: 40px;
  height: 22px;
  bottom: 8px;
  right: 31px;
  text-align: center;
  padding: 0;
  min-height: 22px;
  z-index: 9;
  font-size: 11px;
  line-height: 22px;
  border: none;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(5) {
  position: absolute;
  width: 60%;
  background: none;
  top: 5px;
  left: 5px;
  text-align: center;
  padding: 0;
  min-height: 30px;
  z-index: 9;
  line-height: 42px;
  border: none;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(5)
  img {
  max-width: 100%;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(5)
  span {
  float: left;
  margin-right: 3px;
  position: relative;
  padding: 2px;
  box-sizing: border-box;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(4) {
  position: absolute;
  width: 50%;
  background: none;
  top: 0px;
  right: 0;
  text-align: center;
  padding: 0;
  min-height: 30px;
  z-index: 9;
  line-height: 42px;
  border: none;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(4)
  span {
  line-height: 18px;
  margin-top: 5px;
  text-align: right;
  padding-right: 5px;
  font-size: 12px;
}
.list_contract
  .contract_content_main.list_contract_grid
  .list-content
  .item
  .item-content
  li:nth-child(1) {
  border: none;
}
.contract_list_creat {
  margin-top: 30px;
  background: #f6f7f7;
  padding: 30px;
  position: relative;
  height: 550px;
  overflow-x: auto;
}
.contract_list_creat .md-content {
  display: inline-block;
  width: 100%;
  margin-bottom: 40px;
  box-shadow: 0 0px 4px #cdcdcd;
  padding-bottom: 15px;
}
.contract_list_creat .md-content .item_name_contract {
  display: inline-block;
  width: 100%;
  height: 150px;
  border-radius: 6px;
  text-align: center;
  background: #2d89ef;
  color: #fff;
  margin-bottom: 10px;
}
.contract_list_creat .md-content .item_ct_contract {
  padding-left: 10px;
}
.contract_list_creat .md-content .item_ct_contract p {
  margin-bottom: 5px;
}
.contract_list_creat .md-content .item_ct_link {
  padding-left: 15px;
}
.contract_creat_title {
  font-weight: bold;
}
.contract_group .list-group .list-group-content ul li.company_list .select-group .avatar-group {
  position: relative;
  width: auto;
}
.contract_group .list-group .list-group-content ul li.company_list .select-group {
  width: 40px;
}
.contract_group .list-group .list-group-content ul li.company_list .select-group button {
  background: none;
  border: none;
  position: relative;
  top: initial;
  left: initial;
  right: initial;
  width: auto;
}
.call_button {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.call_button button {
  width: 250px;
  height: 44px;
  background: #2d89ef;
  text-align: center;
  line-height: 42px;
  padding: 0;
  padding-bottom: 4px;
  border-radius: 0;
  font-size: 18px;
  margin: 0 3px;
  color: #fff;
}
.call_button button:hover {
  color: #fff;
}
.contract_creat {
  padding-bottom: 100px;
}
.contract_creat .main-body-content .content-input-item .contract_list-input {
  padding-right: 10px;
  padding-left: 10px;
}
.contract_creat
  .main-body-content
  .content-input-item
  .contract_list-input
  .form-edit
  .item-company
  .col-sm-3 {
  width: 30%;
}
.contract_creat
  .main-body-content
  .content-input-item
  .contract_list-input
  .form-edit
  .item-company
  .col-sm-3
  label {
  width: 100%;
}
.contract_creat
  .main-body-content
  .content-input-item
  .contract_list-input
  .form-edit
  .item-company
  .col-sm-5 {
  width: 70%;
}
.contract_creat
  .main-body-content
  .content-input-item
  .contract_list-input
  .form-edit
  .item-company
  .button-submit {
  color: #222;
}
.contract_creat #modal_add_contract .modal-header {
  border: none;
}
.contract_creat #modal_add_contract .modal-footer {
  border: none;
}
.contract_creat .add_contract_sub {
  outline: none;
  float: right;
  padding: 0;
  margin-top: -5px;
}
.contract_creat .title-header {
  margin-top: 30px;
}
.contract_creat .contract_list_setting {
  background: #f8fcfe;
  margin-top: 10px;
  padding: 30px;
  border-radius: 7px;
}
.contract_creat .pdl-20 {
  padding-left: 20px;
}
.contract_creat .col-orange {
  color: orange;
}
.contract_creat .title-header h5 {
  background: #2d89ef;
  padding: 10px;
  color: #fff;
  border-radius: 7px;
  margin-bottom: 0px;
}
.contract_creat .contract_list_setting .item_name_contract {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 10px;
}
.contract_creat .yellow {
  background: #fff2cc;
}
.contract_creat .btn-orange {
  border-color: #d6b656;
}
.contract_creat .content-input-item {
  background: #f8fcfe;
  margin-top: 10px;
  border-radius: 7px;
}
.contract_creat .contract_list-input {
  padding: 10px 30px;
}
.contract_creat .contract_list-input {
  padding: 10px 30px;
}
.contract_creat .contract_list-input ul {
  list-style: none;
  overflow: hidden;
  padding-left: 0px;
}
.contract_creat .contract_list-input ul li {
  width: 32%;
  float: left;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  margin-right: 2%;
}
.contract_creat .contract_list-input ul li:nth-child(3n) {
  margin-right: 0;
}
.contract_creat .contract_list-input ul li .item-left {
  width: 60%;
  float: left;
  text-align: center;
  position: relative;
}
.contract_creat .contract_list-input ul li .item-right {
  width: 40%;
  float: left;
  text-align: center;
  position: relative;
}
.contract_creat .contract_list-input ul li select {
  border-radius: 0;
  outline: 0;
  box-shadow: none;
  font-size: 13px;
}
.contract_creat .contract_list-input ul li .button_remove {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  right: 6px;
  outline: none;
  z-index: 8;
  padding: 0;
  line-height: 1;
}
.contract_creat .contract_list-input ul li button {
  width: 100%;
  border-radius: 0px;
  padding: 0px;
  display: inline-block;
  height: 42px;
  line-height: 18px;
  outline: none;
  padding-left: 5px;
  padding-right: 20px;
  position: relative;
  max-height: 42px;
  font-size: 13px;
  color: #333;
}
.contract_creat .contract-list-radio {
  margin-top: 20px;
}
.contract_creat .contract-list-radio .form-group .col-form-label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contract_creat .send_settings_content {
  margin-top: 20px;
}
.contract_creat .contract-list-send-setting .form-group .col-form-label {
  padding-left: 40px;
}
.contract_creat .contract-list-send-setting .form-group p {
  word-break: break-all;
}
.contract_creat .content-send-settings .submit-form {
  display: inline-block;
  margin-bottom: 30px;
  margin-top: 30px;
}
.contract_creat .content-send-settings .submit-form button {
  width: 120px;
  height: 44px;
  background: #2d89ef;
  text-align: center;
  line-height: 42px;
  padding: 0;
  padding-bottom: 4px;
  border-radius: 0;
  font-size: 18px;
  margin: 0 3px;
  color: #fff;
  float: right;
  margin-left: 15px;
}
.user_profile_content {
  padding-top: 50px;
}
.user_profile_content .avartar-upload {
  position: relative;
}
.user_profile_content .avartar-upload .img-avarta {
  position: absolute;
}
.user_profile_content .avartar-upload .img-avarta button {
  border: 0px;
  background: transparent;
  position: absolute;
  bottom: 0px;
  right: 10px;
}
.user_profile_content .col-form-label .note-vali {
  color: red;
}
.user_profile_content .form-control {
  font-size: 14px;
}
.user_profile_content .button-submit {
  width: 120px;
  height: 44px;
  background: #2d89ef;
  text-align: center;
  line-height: 42px;
  padding: 0;
  padding-bottom: 4px;
  -webkit-border-radius: 0;
  border-radius: 0;
  font-size: 18px;
  margin: 0;
  color: #fff;
}
.user_profile_content .item_form_signature {
  background: #ddd;
  text-align: center;
  padding: 20px 0;
}
.content-send-settings .submit-form button {
  width: 120px;
  height: 44px;
  background: #2d89ef;
  text-align: center;
  line-height: 42px;
  padding: 0;
  padding-bottom: 4px;
  -webkit-border-radius: 0;
  border-radius: 0;
  font-size: 18px;
  margin: 0;
  color: #fff;
  float: right;
  margin-left: 15px;
}
.contract_edit .form-edit form button {
  width: 150px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  height: 42px;
}
.contract_edit .form-edit form .form-group input {
  border: 1px solid #d6b656;
  background: #fff2cc;
}
.contract_edit .submit-form {
  overflow: hidden;
  margin-top: 40px;
}
.contract_edit .submit-form button {
  width: 120px;
  height: 44px;
  background: #2d89ef;
  text-align: center;
  line-height: 42px;
  padding: 0;
  padding-bottom: 4px;
  -webkit-border-radius: 0;
  border-radius: 0;
  font-size: 18px;
  margin: 0;
  color: #fff;
  float: right;
  margin-left: 15px;
}
.contract_content_license_header {
  padding-top: 50px;
}
.list_contract .contract_content_main.list_contract_grid .list-content .item .item-content {
  overflow: initial !important;
}
.contract_content_main .list-title-table-button {
  margin-top: 20px;
  margin-bottom: 10px;
  overflow: hidden;
}
.contract_content_main .list-title-table-button h3 {
  float: left;
}
.contract_content_main .list-title-table-button .botton-download {
  float: right;
}
.contract_content_main .list-title-table-button .botton-download button {
  height: 42px;
  background: #2d89ef;
  text-align: center;
  line-height: 42px;
  padding: 0 15px;
  padding-bottom: 4px;
  -webkit-border-radius: 0;
  border-radius: 0;
  font-size: 18px;
  margin: 0;
  color: #fff;
  float: right;
  margin-left: 15px;
}
.list_contract .contract_content_main ul.list-history li.item-title li:nth-child(1) {
  font-weight: 700 !important;
  text-align: center !important;
}
.list_contract .contract_content_main ul.list-history li .item-content li:nth-child(1) {
  width: 10%;
  line-height: 21px;
  font-weight: 500;
}
.list_contract .contract_content_main ul.list-history li .item-content li:nth-child(2) {
  width: 15%;
}
.list_contract .contract_content_main ul.list-history li .item-content li:nth-child(3) {
  width: 15%;
}
.list_contract .contract_content_main ul.list-history li .item-content li:nth-child(4) {
  width: 50%;
}
.list_contract .contract_content_main ul.list-history li .item-content li:nth-child(5) {
  width: 10%;
}
.list_contract.kri_contract_license
  .contract_content_main
  ul.list-history
  li.item-title
  li:nth-child(1) {
  line-height: 40px;
}
.list_contract.kri_contract_license .contract_content_main ul li:nth-child(1) ul li:nth-child(4) {
  line-height: 40px;
}
.contract_form_search_group {
  padding-right: 60px;
  position: relative;
}
.contract_form_search_group .search-add-group {
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
  box-shadow: none;
}
.input_header {
  background: transparent;
  border: none;
  color: #fff;
  padding: 0;
  height: 26px;
  background: #2d89ef;
}
.input_header:focus {
  background: #2d89ef;
  color: #fff;
}
.edit-title-contract {
  padding: 0;
}
#edit-title-contract .modal-header {
  border: none;
}
#edit-title-contract .modal-footer {
  border: none;
}
.group-company {
  margin-bottom: 60px;
  position: relative;
}
.group-company .remove_contract_sub {
  position: absolute;
  width: 32px;
  height: 32px;
  padding: 0;
  top: 50%;
  margin-top: -16px;
  right: 10%;
}
input.camera_upload {
  position: absolute;
  opacity: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  z-index: 9;
  cursor: pointer;
}
.contract_group .form-search {
  position: relative;
}
.contract_group .form-search button {
  position: absolute;
  width: 100px;
  height: 42px;
  top: 0;
  right: 0;
  text-align: center;
  line-height: 40px;
  background: #2d89ef;
  color: #fff;
  padding: 0;
  border-radius: 0;
}
.contract_group .list-group .list-group-content ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  max-height: 40vh;
  overflow-y: auto;
}
.contract_group .list-group .list-group-content ul li {
  position: relative;
  padding-left: 120px;
  padding-top: 8px;
  min-height: 66px;
  background: #beeafa;
  margin-bottom: 15px;
}
.contract_group .list-group .list-group-content ul li.color1 {
  background: #f8c7ed;
}
.contract_group .list-group .list-group-content ul li .select-group {
  position: absolute;
  width: 140px;
  height: 36px;
  top: 15px;
  right: 15px;
}
.contract_group .list-group .list-group-content ul li button {
  position: absolute;
  width: 90px;
  height: 36px;
  top: 15px;
  right: 110px;
}
.contract_group .list-group .list-group-content ul li button.cancel {
  right: 5px;
}
.contract_group .list-group .list-group-content ul li .avatar-group {
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 66px;
  font-size: 16px;
}
.contract_group .list-group .list-group-content ul li p {
  margin-bottom: 5px;
}
.contract_group .list-group .list-group-content ul li p span {
  margin-left: 5px;
  font-size: 16px;
}
.contract_group .list-group .list-group-content ul li p img {
  float: left;
}
.contract_group .list-group .list-group-content .list-item {
  position: relative;
  height: 60vh;
  overflow-y: auto;
}
.contract_group .list-group .list-group-content .list-item ul {
  height: auto;
  position: initial;
  overflow: initial;
  padding-left: 30px;
}
.contract_group .list-group .add-group {
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}
.contract_group.modal-add-group .form-search {
  position: relative;
}
.contract_group.modal-add-group .form-search button {
  position: absolute;
  width: 100px;
  height: 42px;
  top: 0;
  right: 0;
  text-align: center;
  line-height: 40px;
  background: #2d89ef;
  color: #fff;
  padding: 0;
  border-radius: 0;
}
.contract_group.modal-add-group .list-group .list-group-content ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  height: 50vh;
  overflow-y: auto;
}
.contract_group.modal-add-group .list-group .list-group-content ul li {
  position: relative;
  padding-left: 120px;
  padding-top: 8px;
  min-height: 66px;
  background: #beeafa;
  margin-bottom: 15px;
}
.contract_group.modal-add-group .list-group .list-group-content ul li.color1 {
  background: #f8c7ed;
}
.contract_group.modal-add-group .list-group .list-group-content ul li .select-group {
  position: absolute;
  width: 140px;
  height: 36px;
  top: 15px;
  right: 15px;
}
.contract_group.modal-add-group .list-group .list-group-content ul li .avatar-group {
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 66px;
  font-size: 16px;
}
.contract_group.modal-add-group .list-group .list-group-content ul li p {
  margin-bottom: 5px;
}
.contract_group.modal-add-group .list-group .list-group-content ul li p span {
  margin-left: 5px;
  font-size: 16px;
}
.contract_group.modal-add-group .list-group .list-group-content ul li p img {
  float: left;
}
.contract_group.modal-add-group .list-group .list-group-content .list-item {
  position: relative;
  height: 60vh;
  overflow-y: auto;
}
.contract_group.modal-add-group .list-group .list-group-content .list-item ul {
  height: auto;
  position: initial;
  overflow: initial;
  padding-left: 30px;
}
.contract_group.modal-add-group .list-group .add-group {
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}
.modal-add-group .modal-header {
  display: inline-block;
  width: 100%;
}
.modal-add-group .modal-dialog {
  max-width: 800px;
}
.modal-add-group .modal-dialog .list-group-content ul li .avatar-group {
  background: #7bd8fb;
}
.modal-add-group .modal-dialog .list-group-content ul li .check-box {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 20px;
  right: 15px;
}
.modal-add-group .modal-dialog .list-group-content ul {
  height: 50vh;
  overflow: auto;
}
.modal-add-group .modal-dialog .modal-footer button {
  min-width: 120px;
}
.login_content .submit-form button {
  margin: 0 3px;
}
.login_content .note-form p {
  color: #2d89ef;
}
a.btn {
  height: 44px;
  background: #2d89ef;
  text-align: center;
  line-height: 42px;
  padding: 0 30px;
  padding-bottom: 4px;
  border-radius: 0;
  font-size: 16px;
  margin: 0 3px;
  color: #fff;
}
p.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.contract_edit .submit-form button.button-deny-signing {
  background: red;
  float: left;
  margin-left: 0;
}
.contract_edit .submit-form button.gray {
  background: gray;
}
.list_contract .contract_content_tool .grid-checkall {
  display: none;
}
.list_contract .contract_content_tool.list_contract_grid_tool .grid-checkall {
  display: inline-block;
  float: left;
  margin-top: 8px;
  padding-left: 4px;
}
.list_contract .contract_content_tool.list_contract_grid_tool .grid-checkall span {
  margin-left: 5px;
}
.list_contract .click-grid .list-icon {
  display: none;
}
.list_contract .click-grid.click-list .list-icon {
  display: inline-block;
}
.list_contract .click-grid.click-list .grid-icon {
  display: none;
}
.contract_group .list-group .list-group-content.search-cotnent ul li button {
  width: auto;
  right: 10px;
}
.login .page-container .login_form .login_content h1 {
  font-size: 34px;
}
.list_contract.kri_contract_license
  .contract_content_main
  ul.list-history
  li
  .item-content
  li:nth-child(2) {
  width: 25%;
}
.list_contract.kri_contract_license
  .contract_content_main
  ul.list-history
  li
  .item-content
  li:nth-child(3) {
  width: 40%;
}
.list_contract.kri_contract_license
  .contract_content_main
  ul.list-history
  li
  .item-content
  li:nth-child(4) {
  width: 25%;
}
.modal-qrcode .modal-dialog {
  max-width: 600px;
}
.modal-qrcode .modal-dialog .file-name {
  background: #2d89ef;
  color: #fff;
  border-radius: 3px;
  padding: 10px 15px;
  width: 100%;
}
.modal-qrcode .modal-dialog .img-qr {
  padding: 30px;
  text-align: center;
}
.modal-qrcode .modal-dialog .img-qr img {
  width: 100%;
}
.button-submit {
  height: 44px;
  background: #2d89ef;
  text-align: center;
  line-height: 42px;
  padding: 0px 30px;
  padding-bottom: 4px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  font-size: 18px;
  margin: 0;
  color: #fff;
}
.list_contract.list_contract_company .contract_content_main ul li .item-content li:nth-child(2) {
  width: 25%;
}
.contract_setting .submit-form {
  text-align: center;
}
.contract_setting .submit-form button {
  padding: 0 60px;
}
.contract_code form {
  text-align: center;
  margin-top: 30px;
}
.contract_code form input {
  max-width: 500px;
  display: inline-block;
}
.contract_code form span.error {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}
.contract_group_ct .menu_group ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  padding-top: 20px;
}
.contract_group_ct .menu_group ul li {
  float: left;
  width: 100%;
}
.contract_group_ct .menu_group ul li a {
  float: left;
  display: inline-block;
  width: 100%;
  line-height: 40px;
  background: #2d89ef;
  color: #fff;
  padding-left: 15px;
  transition: all 0.3s;
  margin-bottom: 2px;
  text-decoration: none;
}
.contract_group_ct .menu_group ul li a:hover {
  padding-left: 20px;
  transition: all 0.3s;
}
.contract_creat .contract_list_setting {
  border: 1px solid #ced4da;
  padding: 15px;
  padding-right: 0;
  overflow: hidden;
}
.contract_creat .contract_list_setting img {
  max-width: 100%;
}
.contract_slider {
  position: relative;
  padding-top: 40px;
}
.contract_slider .icon_thumbnail {
  position: absolute;
  width: 150px;
  top: 40px;
  right: 0px;
  padding: 15px 15px;
  padding-right: 25px;
  background: #eeeff0;
  height: 100%;
  overflow-y: auto;
}
.contract_slider .icon_thumbnail ul li {
  margin-bottom: 20px;
  border: 3px solid #2d89ef;
  position: relative;
  overflow: hidden;
}
.contract_slider .icon_thumbnail ul li span {
  color: #fff;
  position: absolute;
  left: 3px;
  bottom: 0;
}
.contract_slider .icon_thumbnail ul li:before {
  bottom: 0%;
  left: 0%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #2d89ef;
  border-width: 35px;
  margin-left: -35px;
}
.contract_slider ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.contract_slider .toolbar {
  position: absolute;
  margin-bottom: 15px;
  width: 100%;
  height: 50px;
  top: -8px;
  left: 0;
  padding-right: 130px;
}
.contract_slider .toolbar ul {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.contract_slider .toolbar ul li {
  display: inline-block;
  line-height: 40px;
  margin: 0 10px;
}
.contract_slider .toolbar ul li span {
  cursor: pointer;
}
.contract_slider .slider_content {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 50px;
}
.contract_slider .slider_content li {
  margin-bottom: 15px;
}
.contract_slider .slider_content li span {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.contract_slider .slider_content img {
  width: 100%;
}
.contract_slider .main-slider {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  max-height: 100vh;
  padding-right: 150px;
}
.contract_slider #body_slider {
  width: 600px;
  max-width: 870px;
  min-width: 300px;
  transition: all 0.3s;
  position: relative;
}
/* --------------------------------------------------------------------- */
/* .footer
/* --------------------------------------------------------------------- */
.footer {
  /**/
}
.schedule .schedule-tab {
  padding: 20px 30px;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.schedule .schedule-tab .tab-main {
  width: 100px;
  display: inline-block;
  width: 100%;
  position: relative;
  padding-left: 100px;
}
.schedule .schedule-tab .tab-main .tab-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  text-align: center;
  line-height: 32px;
  color: #8191a1;
  font-weight: bold;
}
.schedule .schedule-tab .tab-main .tab-content {
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.schedule .schedule-tab .tab-main .tab-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.schedule .schedule-tab .tab-main .tab-content ul li {
  float: left;
  margin-right: 10px;
}
.schedule .schedule-tab .tab-main .tab-content ul li a {
  display: inline-block;
  line-height: 30px;
  border: 1px solid #cdcdcd;
  float: left;
  height: 32px;
  padding: 0 40px;
  border-radius: 4px;
  color: #3d4956;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
}
.schedule .schedule-tab .tab-main .tab-content ul li a span.status {
  margin-left: 15px;
}
.schedule .schedule-tab .tab-main .tab-content ul li a:hover {
  background: #50b7f0;
  color: #fff;
  text-decoration: none;
  border-color: #50b7f0;
}
.schedule .schedule-tab .tab-main .tab-content ul li.active a {
  background: #50b7f0;
  color: #fff;
  text-decoration: none;
  border-color: #50b7f0;
}
.schedule .schedule-main-content {
  padding: 15px 20px;
}
.schedule .schedule-main-content .schedule-day {
  padding-left: 60px;
  float: left;
  width: 100%;
  background: #e7f3f9;
  margin-bottom: 10px;
  position: relative;
}
.schedule .schedule-main-content .schedule-day .day-title {
  position: absolute;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  top: 0;
  left: 0;
}
.schedule .schedule-main-content .schedule-day ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.schedule .schedule-main-content .schedule-day ul li {
  float: left;
  width: 14.2857%;
}
.schedule .schedule-main-content .schedule-day ul li span {
  text-align: center;
  line-height: 38px;
  display: inline-block;
  width: 100%;
}
.schedule .schedule-main-content .schedule-table-content {
  display: inline-block;
  width: 100%;
  padding-left: 60px;
  height: 600px;
  position: relative;
  overflow-x: auto;
}
.schedule .schedule-main-content .schedule-table-content .schedule-hours {
  width: 60px;
  border-left: 1px solid #ddd;
  height: 4320px;
  position: absolute;
  top: 0px;
  left: 0;
}
.schedule .schedule-main-content .schedule-table-content .schedule-hours:before {
  width: 100%;
  height: 1px;
  background: #ddd;
  top: 0;
  left: 0;
  content: "";
  position: absolute;
}
.schedule .schedule-main-content .schedule-table-content .schedule-hours ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.schedule .schedule-main-content .schedule-table-content .schedule-hours ul li {
  float: left;
  width: 100%;
  text-align: center;
  height: 180px;
  padding-top: 70px;
  position: relative;
}
.schedule .schedule-main-content .schedule-table-content .schedule-hours ul li:before {
  width: 100%;
  height: 1px;
  background: #ddd;
  bottom: 0;
  left: 0;
  content: "";
  position: absolute;
}
.schedule .schedule-main-content .schedule-table-content .schedule-hours ul li span {
  display: inline-block;
  width: 100%;
  color: #7d7d7d;
  line-height: 16px;
  font-size: 12px;
}
.schedule .schedule-main-content .schedule-table-content table {
  border-right: none;
  border-bottom: none;
}
.schedule .schedule-main-content .schedule-table-content tbody tr td {
  width: 14.285714%;
  height: 30px;
  padding: 0;
  border: none;
  line-height: 30px;
  position: relative;
  text-align: left;
  padding-left: 10px;
}
.schedule .schedule-main-content .schedule-table-content tbody tr td:before {
  width: 100%;
  height: 1px;
  background: #ddd;
  bottom: 0;
  left: 0;
  content: "";
  position: absolute;
}
.schedule .schedule-main-content .schedule-table-content tbody tr td:after {
  width: 1px;
  height: 100%;
  background: #ddd;
  top: 0;
  right: 0;
  content: "";
  position: absolute;
}
.schedule .schedule-main-content .schedule-table-content tbody tr td.active {
  background: #feffc1;
}
.schedule .schedule-main-content .schedule-table-content tbody tr td.active:before {
  display: none;
}
.schedule .schedule-main-content .schedule-table-content tbody tr td.active2 {
  background: #8191a1;
  color: #fff;
}
.schedule .schedule-main-content .schedule-table-content tbody tr td.active2:before {
  display: none;
}
.schedule .schedule-top {
  float: right;
  margin-bottom: 20px;
  padding-right: 10px;
}
.schedule .schedule-top span.title-date {
  font-weight: bold;
  font-size: 16px;
}
.schedule .schedule-top span {
  line-height: 30px;
  display: inline-block;
  margin-left: 20px;
}
.schedule .schedule-top span.navi {
  cursor: pointer;
  padding: 0 15px;
  text-align: center;
}
.schedule .schedule-top span.navi:hover i {
  color: #26a69a;
}
.schedule .schedule-top span.navi i {
  font-size: 24px;
  float: left;
}
.schedule .schedule-content .title {
  float: left;
  font-weight: bold;
  font-size: 18px;
  padding-left: 15px;
}
.schedule.schedule-full .schedule-main-content .schedule-day ul li {
  width: 7.14285714%;
}
.schedule.schedule-full .schedule-main-content .schedule-table-content tbody tr td {
  width: 7.14285714%;
}
.full-schedule .schedule .schedule-main-content {
  padding-left: 0;
  padding-right: 0;
}
.full-schedule .schedule .schedule-content .title {
  padding-left: 0;
}
.full-schedule .schedule-tab {
  float: left;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .page-header {
    display: none;
  }
  .call_button button {
    margin-bottom: 15px;
  }
  body {
    background: #263239;
    color: #fff;
  }
  .user_profile_content {
    padding-top: 0;
  }
  .login .page-container .login_form .login_content h1 {
    color: #fff;
    font-size: 26px;
  }
  .login .login_wrapper {
    max-width: 100%;
    position: relative;
    left: initial;
    margin-left: 0;
  }
  .forgot_password.login .login_content .login-note p {
    font-size: 13px;
  }
  .user_profile_content .submit-form {
    display: none;
  }
  .user-profile {
    padding-bottom: 10px;
  }
  .login .page-container .login_form .login_content .item_form input {
    font-size: 13px;
  }
  .login .login_wrapper.register {
    max-width: 100%;
    position: relative;
    left: initial;
    margin-left: 0;
  }
  .login .login_wrapper.register .radio-list {
    padding-left: 0;
  }
  .login .login_wrapper.register .form-group label {
    text-align: left !important;
  }
  .login .login_wrapper.register .form-group {
    margin-bottom: 0;
  }
  .login .login_wrapper.register .form-group .if_check {
    font-size: 13px;
  }
  .login .login_wrapper.register .login_form .login_content .submit-form .button-submit {
    width: 140px;
    font-size: 13px;
  }
  .login .page-container .login_form .radio-list {
    margin-bottom: 0;
  }
  .page-header {
    position: fixed;
    top: inherit;
    bottom: 0;
    z-index: 99;
  }
  .list_contract .list_contract_top {
    padding-right: 15px;
    padding-left: 15px;
  }
  .list_contract .list_contract_top .contract-setting ul li {
    margin: 0;
    margin-right: 15px;
  }
  .list_contract .list_contract_top .contract-setting {
    width: 100%;
    margin-top: 10px;
  }
  .list_contract .contract_search .setting_contract_button {
    width: 100%;
    margin-bottom: 10px;
  }
  .list_contract .contract_search .md {
    width: 100%;
    margin-bottom: 10px;
  }
  .list_contract .contract_search .md select {
    background: none;
    color: #fff;
  }
  .list_contract .contract_search .md select option {
    color: #111;
  }
  .list_contract .contract_search .md input {
    background: none;
    color: #fff;
  }
  .list_contract .contract_content_main {
    display: inline-block;
    width: 100%;
    margin-bottom: 80px;
    position: relative;
    overflow-x: auto;
  }
  .list_contract .contract_content_main ul {
    min-width: 800px;
  }
  .list_contract .contract_content_main.list_contract_grid {
    overflow-x: hidden;
  }
  .list_contract .contract_content_main.list_contract_grid .list-content {
    width: 100%;
    margin-left: 0;
    min-width: 100%;
  }
  .list_contract .contract_content_main.list_contract_grid .list-content .item {
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
    padding-left: 0;
  }
  .list_contract
    .contract_content_main.list_contract_grid
    .list-content
    .item
    .item-content
    li
    input[type="checkbox"] {
    left: 3px;
    top: 3px;
  }
  .list_contract .contract_content_main.list_contract_grid .list-content .item ul {
    width: 100%;
    min-width: 100%;
  }
  .list_contract
    .contract_content_main.list_contract_grid
    .list-content
    .item
    .item-content
    li:nth-child(3) {
    background: #263239;
  }
  .list_contract .contract_content_main ul li .item-content li {
    border-bottom: none;
  }
  .list_contract
    .contract_content_main.list_contract_grid
    .list-content
    .item
    .item-content
    li:nth-child(3) {
    height: 41px;
    bottom: 0;
  }
  .list_contract .contract_content_main ul li .item-content li span.number {
    background: #263239;
  }
  .list_contract .contract_content_main.list_contract_grid .list-content .item .item-content {
    background: none;
  }
  .contract_creat .contract_list-input ul li {
    width: 100%;
  }
  .contract_creat .contract-list-radio .row .col-sm-2 {
    width: 15%;
  }
  .contract_creat .contract-list-radio .row .col-sm-8 {
    width: 85%;
  }
  .contract_creat .contract-list-send-setting .form-group .col-form-label {
    padding-left: 15px;
  }
  .creat_qrcode {
    float: left;
  }
  .note_qrcode {
    display: flex;
  }
  .contract_slider #body_slider {
    width: 240px;
  }
  .contract_slider .icon_thumbnail {
    display: none;
  }
  .contract_slider .toolbar {
    padding-right: 0;
  }
  .contract_slider .main-slider {
    padding-right: 0;
  }
  .contract_creat .contract_list_setting {
    padding-right: 15px;
  }
  .contract_slider #body_slider {
    padding-right: 0;
    padding-left: 0;
    min-width: 180px;
  }
  .modal .modal-body,
  .contract_assignment.modal-confirm-deny-sign .modal-body,
  .contract_assignment.modal-draw-signature .modal-body,
  .contract_assignment.modal-confirm-sign .modal-body {
    color: #333;
  }
  .modal .modal-title,
  .contract_assignment.modal-confirm-deny-sign .modal-title,
  .contract_assignment.modal-draw-signature .modal-title,
  .contract_assignment.modal-confirm-sign .modal-title {
    color: #333;
  }
  .user_profile_content .avartar-upload .img-avarta {
    position: relative;
  }
  .user_profile_content .avartar-upload .img-avarta img {
    width: 100%;
  }
  .user_profile_content {
    position: relative;
    overflow: hidden;
  }
  .user_profile_content label {
    text-align: left !important;
  }
  .contract_creat
    .main-body-content
    .content-input-item
    .contract_list-input
    .form-edit
    .item-company.update-row-84
    .col-sm-3 {
    width: 60%;
  }
  .contract_creat
    .main-body-content
    .content-input-item
    .contract_list-input
    .form-edit
    .item-company.update-row-84
    .col-sm-3
    label {
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    height: auto;
  }
  .contract_creat
    .main-body-content
    .content-input-item
    .contract_list-input
    .form-edit
    .item-company.update-row-84
    .col-sm-5 {
    width: 40%;
  }
  .contract_creat
    .main-body-content
    .content-input-item
    .contract_list-input
    .form-edit
    .item-company.update-row-84
    .col-sm-5
    button {
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .list_contract .list_contract_top .contract-setting ul li {
    margin-right: 10px;
  }
}
@media screen and (max-width: 380px) {
  .list_contract .list_contract_top .contract-setting ul li {
    margin-right: 5px;
  }
}
