.rc-pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem
}

.rc-pagination .rc-pagination-item a {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff!important;
    background-color: #fff;
    border: 1px solid #dee2e6
}

.rc-pagination .rc-pagination-item a:not(:disabled):not(.disabled) {
    cursor: pointer
}

.rc-pagination .rc-pagination-item-active a {
    z-index: 1;
    color: #fff!important;
    background-color: #007bff;
    border-color: #007bff
}

.rc-pagination .rc-pagination-next button,
.rc-pagination .rc-pagination-prev button {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff!important;
    background-color: #fff;
    border: 1px solid #dee2e6
}

.rc-pagination .rc-pagination-next button:not(:disabled):not(.disabled),
.rc-pagination .rc-pagination-prev button:not(:disabled):not(.disabled) {
    cursor: pointer
}

.rc-pagination .rc-pagination-prev button:after {
    content: "\00ab"
}

.rc-pagination .rc-pagination-next button:after {
    content: "\00bb"
}

.rc-pagination .rc-pagination-disabled button {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6
}

.rc-pagination .rc-pagination-total-text {
    font-size: .75rem;
    margin: 0 10px
}

.rc-pagination-jump-prev button, .rc-pagination-jump-next button {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff!important;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.rc-pagination-jump-prev button:after, .rc-pagination-jump-next button:after {
    display: block;
    content: '•••';
}