.contract-draw-signature-canvas {
  width: 100%;
  height: 300px;
  background-color: #ccc;
}

.contract-draw-signature-action {
  text-align: right;
  margin-bottom: 10px;
}

.contract-signature-wrapper {
  clear: both;
}

.contract-signature-image {
  border: 1px solid #d6b656;
  background-color: #fff2cc;
  margin-right: 20px;
  float: left;
  width: 50%;
  height: 80px;
  text-align: center;
}

.contract-signature-image-grey {
  border: 1px solid #ddd;
  background-color: #eee;
}

.contract-signature-image > img {
  height: 60px;
  margin: 10px 0;
}

.page-error {
  text-align: center;
  font-size: 24px;
  margin-top: 100px;
}

.verify-mobile-phone-input-wrapper {
  display: flex;
  margin-top: 30px;
}

.verify-mobile-phone-input-wrapper .form-group {
  margin-right: 5%;
}

.verify-mobile-phone-input-wrapper .form-group:last-child {
  margin-right: 0;
}

.verify-mobile-phone-input-wrapper input {
  text-align: center;
  font-size: 20px;
}

.has-error input {
  border: 1px solid red;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}
